import React, { useEffect, useState } from "react";
import { Card, Col, Form, Input, Label, Row ,ModalBody,Modal} from "reactstrap";
import { useAuth } from "../../context/AuthContext";
import { useApi } from "../../context/ApiContext";
const ManageProfilePage = ({name,setName,btnStatus,setBtnStatus,uploadProfileImage,imageUpdated}) => {
  const { currentUser,updateDisplayName } = useAuth();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);
  const Api = useApi()
  function handleChange(e) {
    let input = e.target.value;
    setName(input);
    setBtnStatus(false);
  }
  const updateUser = async ()=>{
      if (imageUpdated) {
        let file = document.getElementById('profile-img-file-input').files[0];
        let profile_status = await uploadProfileImage(file,currentUser.uid)
      }
      let db_status = await Api.UpdateUsername({user:currentUser,name:name})
      let status = await updateDisplayName(name);
      setUpdateStatus(true)
      toggleErrorModal()
    }
    return (
    <>
      <Col>
        <Card>
          <Row className="m-2">
            <Col>
              <Form className="mt-2">
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="firstnameInput" className="form-label">
                        Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="firstnameInput"
                        placeholder="Enter your firstname"
                        value={name ? name : ""}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="emailInput" className="form-label">
                        Email Address
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="emailInput"
                        placeholder="Enter your email"
                        value={currentUser.email}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={btnStatus}
                        onClick={updateUser}
                      >
                        Updates
                      </button>
                      <button type="button" className="btn btn-soft-success">
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
      <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0  status-popup">
            <div
              className={
                updateStatus
                  ? "alert alert-success alert-dismissible  show m-0"
                  : "alert alert-danger alert-dismissible  show m-0"
              }
              role="alert"
            >
              {updateStatus ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: "Details have been updated",
                  }}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: "Something went wrong" }}
                />
              )}
              <button
                type="button"
                className="btn-close alrt-clse"
                onClick={toggleErrorModal}
                aria-label="Close"
              ></button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default ManageProfilePage;
