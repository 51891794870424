import React from "react";

import MetaTags from "react-meta-tags";
import Captcha from "./captcha";
import { Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
const captcha = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Captcha</title>
          </MetaTags>
          <BreadCrumb title="Captcha" pageTitle="Dashboards" />
          <Captcha />
        </Container>
      </div>
    </>
  );
};

export default captcha;
