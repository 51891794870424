import React from "react";
import MetaTags from "react-meta-tags";
import Jurisdictions from "./jurisdictions";
import { Col, Container, Row } from "reactstrap";
import "./jurisdictions.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
const Monitoring = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Jurisdictions</title>
          </MetaTags>
          <BreadCrumb title="Jurisdictions" pageTitle="Dashboards" />
          <Jurisdictions />
        </Container>
      </div>
    </>
  );
};

export default Monitoring;
