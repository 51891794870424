import react, { useEffect, useState } from "react";
import Table from "./Tables";
import { useApi } from "../../context/ApiContext";
import { Row, Col } from "reactstrap";

function Captcha() {
  const Api = useApi();
  const [rows, setRows] = useState([]);
  const [jurisdictions, setjurisdictions] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Api.getCaptcaData().then((data) => {
      setRows(data.rows);
      setjurisdictions(data.jurisdictions);
      setLoading(false)
    });
  }, []);
  return (
    <>
      <Row>
        <Col>
          <Table
            rowdata={rows}
            setRows={setRows}
            jurisdictions={jurisdictions}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
}

export default Captcha;
