import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Button,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import MapComponent from "./MapComponent";
import { useApi } from "../../context/ApiContext";
const LinkSpotLocator = () => {
  const Api = useApi();
  const [totalWebsiteCount, settotalWebsiteCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [isFiltersactive, setIsFiltersactive] = useState(false);
  const [activeCategry,setActiveCategory] = useState(null)
  const [siteCounts,setSiteCounts]= useState([]);

  const UpdateCategoryActive = (selectedValue) => {
    if (activeCategry === selectedValue) {
      setActiveCategory(null);
    } else {
      setActiveCategory(selectedValue);
    }
  };

  useEffect(() => {
    const data = { request_for: "get_categories_and_sites" };
    Api.GetTotalWebistesAndCategories(data).then((response) => {
      const { categories, websites_count,state_counts } = response;
      setCategories(categories);
      settotalWebsiteCount(websites_count);
      setSiteCounts(state_counts)
      setLoading(false);
    });
  }, [Api]);
  const toggleFilters = () => {
    setIsFiltersactive(!isFiltersactive);
  };

  const handleFilterChange = async(filterName, value) => {
    setSelectedFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };
  
  useEffect(() => {
    UpdateFilterCounts();
  }, [selectedFilters,activeCategry]);

  const UpdateFilterCounts = async () => {
    const filters_to_send = activeCategry!=null ? Object.assign({}, selectedFilters,{category:activeCategry}) : selectedFilters
    const data = { request_for: "get_filter_counts", filters:filters_to_send };
    const filters = await Api.getFilteredCounts(data); // Assuming Api.getFilteredCounts returns a Promise
    if (filters && filters.websites_count) {
      settotalWebsiteCount(filters.websites_count);
    }
    if (filters && filters.state_counts) {
      setSiteCounts(filters.state_counts);
    }
  };
  const clearFilters = () => {
    setSelectedFilters({});
  };
  useEffect(() => {
    document.body.classList.add('hide-scrollbar');
    return () => {
      document.body.classList.remove('hide-scrollbar');
    };
  }, [])
  return (
    <>
      <div className="page-content" style={{ overflow: "hidden", }}>
        <Container fluid>
          <MetaTags>
            <title>Websites Directory</title>
          </MetaTags>
          <BreadCrumb
            title="Websites Directory"
            pageTitle="Dashboards"
            WebsitesPage={true}
          />
          <div className="d-flex align-items-center justify-content-md-between  mb-3 ">
            <h6 className="pb-0 mt-3 mb-2 ">
              Total Websites: {totalWebsiteCount}
            </h6>
            <Button color="secondary" className="mt-2 d-flex align-items-center justify-content-center" style={{gap:5}} onClick={toggleFilters}>
              <i className="bx bx-filter-alt"></i>Filters { Object.keys(selectedFilters).length > 0 && "("+ Object.keys(selectedFilters).length +")" }
            </Button>
          </div>
          <Offcanvas
            isOpen={isFiltersactive}
            direction="end"
            toggle={toggleFilters}
            id="offcanvasRight"
            className="border-bottom"
          >
            <OffcanvasHeader toggle={toggleFilters} id="offcanvasRightLabel">
              Filters
            </OffcanvasHeader>
            <OffcanvasBody className="p-4 overflow-hidden">

              <div className="filter-section">
                <div className="d-flex w-100 align-items-end justify-content-end  flex-row">
                  <Button color="danger" onClick={clearFilters}> Clear </Button>
                </div>

                <h5 className="mt-3">Scrapable</h5>
                <div
                  className="d-flex align-items-center flex-row mt-3"
                  style={{ gap: 10 }}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="scrapableOptions"
                      id="scrapableYes"
                      value="true"
                      checked={selectedFilters.scrapable === true} 
                      onChange={() => handleFilterChange('scrapable', true)}
                    />
                    <label className="form-check-label" htmlFor="scrapableYes">
                      Yes
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="scrapableOptions"
                      id="scrapableNo"
                      value="false"
                      checked={selectedFilters.scrapable === false} 
                      onChange={() => handleFilterChange('scrapable', false)}

                    />
                    <label className="form-check-label" htmlFor="scrapableNo">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </OffcanvasBody>
          </Offcanvas>

          {loading ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          ) : (
            <MapComponent
              selectedFilters = {selectedFilters}
              categories={categories}
              activeCategry={activeCategry}
              setCategories={setCategories}
              settotalWebsiteCount={settotalWebsiteCount}
              UpdateCategoryActive={UpdateCategoryActive}
              siteCounts={siteCounts}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default LinkSpotLocator;
