import React, { useContext, useState, useEffect } from 'react'
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  createUserWithEmailAndPassword,
  //   sendPasswordResetEmail,
  //   updateEmail,
  updateProfile,
} from 'firebase/auth'
import * as api from '../api'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../firebase'
import { storage } from '../firebase'; 
const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [userIsAdmin, setUserIsAdmin] = useState(false)
  const [userIsDeveloper, setUserIsDeveloper] = useState(false)
  const [availableJurisdictions, setAvailableJurisdictions] = useState(null)
  const [UserProfilePic,SetUserProfilePic] = useState(null)
  const [loading, setLoading] = useState(true)

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }
  function logout() {
    localStorage.clear()
    setUserIsAdmin(false)
    setUserIsDeveloper(false)
    setAvailableJurisdictions([])
    return signOut(auth)
  }
  function updateDisplayName(displayName) {
    return updateProfile(auth.currentUser, {
      displayName,
    })
  }
  function updateProfilePic(photoURL) {
    return updateProfile(auth.currentUser, {photoURL:photoURL})
  }
  async function updateUserPassword(current_password, new_password) {
    var credential = EmailAuthProvider.credential(auth.currentUser.email, current_password)
    try {
      await reauthenticateWithCredential(auth.currentUser, credential)
      await updatePassword(auth.currentUser, new_password)
      return true
    } catch (error) {
      console.log(error)
    }
    return false
  }

  async function reloadUser() {
    await currentUser.reload()
    await auth.updateCurrentUser(auth.currentUser)
    await updateUser(auth.currentUser)
  }

  async function updateUser(user) {
    if (user) {
      const idToken = await user.getIdTokenResult()
      const data = {uid:user.uid}
      const resp = await api.GetJurisdictionsByUser(data,idToken.token)
      const profilepicdata = await getFirebaseDoc(user.uid)
      SetUserProfilePic(profilepicdata)
      setAvailableJurisdictions(resp.jurisdictions || [])
      setUserIsAdmin(idToken.claims.role === 'admin' ? true : false)
      setUserIsDeveloper(idToken.claims.role === 'developer' ? true : false)
    }
    setCurrentUser(user)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      await updateUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  async function getUserToken() {
    const idToken = await auth.currentUser?.getIdTokenResult()
    return idToken?.token
  }

  function setFirebaseDoc(collectionName, docName, updateObj) {
    return setDoc(doc(db, collectionName, docName), updateObj, { merge: true })
  }

  function updateFirebaseDoc(collectionName, docName, updateObj) {
    return updateDoc(doc(db, collectionName, docName), updateObj)
  }

  async function getFirebaseDoc(userId) {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);
  
    if (userSnap.exists()) {
      return userSnap.data();
    } else {
      console.log("No such document!");
      return null;
    }
  
  }


  const uploadProfileImage = async (file, userId) => {
    if (!file) return;
    const storageRef = ref(storage, `profileImages/${userId}`);
    await uploadBytes(storageRef, file);
    const photoURL = await getDownloadURL(storageRef);
  
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
  
    if (!userSnap.exists()) {
      // The user document does not exist, create it with the photoURL
      await setDoc(userRef, { photoURL });
      SetUserProfilePic(photoURL)
    } else {
      // The user document exists, update it with the new photoURL
      await updateDoc(userRef, { photoURL });
      SetUserProfilePic(photoURL)
    }

  };
  

  const value = {
    // states
    currentUser,
    userIsAdmin,
    // functions
    login,
    logout,
    reloadUser,
    updateUserPassword,
    getUserToken,
    getFirebaseDoc,
    signUp,
    setFirebaseDoc,
    updateFirebaseDoc,
    setUserIsAdmin,
    availableJurisdictions,
    updateDisplayName,
    uploadProfileImage,
    userIsDeveloper,
    UserProfilePic,
    updateProfilePic
  }

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
}
