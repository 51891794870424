import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Row,
  Col,
  Input,
  ModalHeader,
  ModalBody,
  Modal,
} from "reactstrap";
import { Grid, _ } from "gridjs-react";
import "./tables.css";

function Cell({ value }) {
  function toSentenceCase(str) {
    return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);
  }

  const [open, setOpen] = useState("0");
  const [modal_animationZoom, setmodal_animationZoom] = useState(false);
  const color = {
    completed: "success",
    error: "danger",
    running: "primary",
  };
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  const data = [["18:09", "18:10"]];

  function tog_animationZoom() {
    setmodal_animationZoom(!modal_animationZoom);
  }
  const BaseExample = () => {
    return (
      <React.Fragment>
        <Grid
          // style={{ minWidth: 220 }}
          className="collapse-table"
          data={data}
          columns={["Start Time", "End Time "]}
        />
        <Row style={{ paddingTop: 15 }}>
          <Col style={{ display: "flex", justifyContent: "space-between " }}>
            <Button
              className="btn-label"
              style={{ width: "45%" }}
              color="primary"
            >
              <i className=" label-icon  align-middle  ri-play-fill" />{" "}
              <span>Run</span>{" "}
            </Button>
            <Button
              style={{ width: "45%" }}
              color="danger"
              onClick={() => tog_animationZoom()}
              className="btn-label"
            >
              {" "}
              <i className=" label-icon  align-middle ri-pencil-fill" /> Edit
            </Button>
            <Modal
              id="flipModal"
              isOpen={modal_animationZoom}
              toggle={() => {
                tog_animationZoom();
              }}
              modalClassName="zoomIn"
              centered
            >
              <ModalHeader
                className="modal-title"
                toggle={() => {
                  tog_animationZoom();
                }}
              >
                Server Details
              </ModalHeader>
              <ModalBody>
                <form action="#">
                  <div className="row g-3">
                    <Col lg={12}>
                      <div>
                        <label htmlFor="firstName" className="form-label">
                          Username
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          id="username"
                          placeholder="Enter Username"
                        />
                      </div>
                    </Col>

                    <Col lg={12}>
                      <label htmlFor="passwordInput" className="form-label">
                        Password
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        id="passwordInput"
                        value="451326546"
                      />
                    </Col>
                    <Col lg={12}>
                      <label htmlFor="ipaddress" className="form-label">
                        {" "}
                        IP Address
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        id="ipaddress"
                        placeholder="Enter IP Address"
                      />
                    </Col>
                    <Col lg={6}>
                      <label htmlFor="servername" className="form-label">
                        {" "}
                        Server Name
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        id="servername"
                        placeholder="Enter Server Name"
                      />
                    </Col>
                    <Col lg={12}>
                      <div className="hstack gap-2 justify-content-end">
                        <Button
                          color="light"
                          onClick={() => setmodal_animationZoom(false)}
                        >
                          Close
                        </Button>
                        <Button color="primary">Submit</Button>
                      </div>
                    </Col>
                  </div>
                </form>
              </ModalBody>
            </Modal>
            {/* <Button
              color="primary"
              className="btn-animation"
              data-text="Export"
            >
              {" "}
              <span>Edit</span>{" "}
            </Button> */}
          </Col>
        </Row>
      </React.Fragment>
    );
  };
  return (
    // <div>
    <Accordion flush={true} open={open} toggle={toggle}>
      <AccordionItem className="accordion-items">
        <AccordionHeader targetId="1">
          <h3 style={{ fontSize: 15, margin: -20, scrollPaddingInline: 2 }}>
            <span className={"badge badge-soft-" + color[value] + " p-2"}>
              {toSentenceCase(value)}
            </span>
          </h3>
        </AccordionHeader>
        <AccordionBody style={{ padding: 2 }} className="tab-1" accordionId="1">
          <BaseExample />
        </AccordionBody>
      </AccordionItem>
    </Accordion>
    /* </div> */
  );
}

export default Cell;
