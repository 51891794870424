import axios from "axios";

const API_URL =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
    ? "http://127.0.0.1:5000/api/"
    : "https://monitoring.thereportfinder.com/api/";
const axiosInstance = axios.create({ baseURL: API_URL });



export async function GetJurisdictionsByUser(data,token) {
  const headers = { Authorization: "Bearer " + token };
  const r = await axiosInstance.post("get-jurisdictions-by-user", data,{
    headers: headers,
  });
  if (r.status === 200) {
    return r.data;
  }
}
