import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import moment from "moment"
const AuthProtected = (props) => {
  const { currentUser } = useAuth();

  if (!currentUser)
    return (
      <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    );

  return props.children;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  const { userIsAdmin, currentUser, userIsDeveloper, logout } = useAuth();
  if (currentUser) {
      const loginTime = parseInt(localStorage.getItem("login_time"));
      if (loginTime === null) {
        localStorage.setItem("login_time", moment().unix());
      } else {
        const differenceInHrs = moment().diff(moment.unix(loginTime), "hours");
        if (differenceInHrs > 1){
          logout()
          return <Redirect to={{ pathname: "/login" }} />
        }
        else{
          localStorage.setItem("login_time", moment().unix());
        }
      }
    if (rest.path == "/proxies") {
      if (userIsAdmin || userIsDeveloper) {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
      } else {
        return <Redirect to={{ pathname: "/monitoring" }} />;
      }
    }
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return <Redirect to={{ pathname: "/login" }} />;
};

export { AuthProtected, AccessRoute };
