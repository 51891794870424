import React, { useState, useEffect } from "react";
import {
  CardBody,
  Row,
  CardHeader,
  Col,
  Card,
  Container,
  Form,
  Input,
  Label,
  Table,
  FormFeedback,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Darklogo from "../../assets/images/flodata.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const InvoiceDetails = ({ jurisdictions }) => {
  const [products, setProducts] = useState(
    jurisdictions.map((name) => ({ name, rate: 0, count: 0 }))
  );
  const [grandTotal, setGrandTotal] = useState(0);
  const [charge, setCharge] = useState(0);

  useEffect(() => {
    setProducts(jurisdictions.map((name) => ({ name, rate: 0, count: 0 })));
  }, [jurisdictions]);
  const dateFormat = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear()
    ).toString();
  };

  const [date, setDate] = useState(dateFormat());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();
    setDate(joinDate);
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      invoiceId: "",
      amount: "",
    },
    validationSchema: Yup.object({
      invoiceId: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      // dispatch(onAddNewInvoice(newInvoice));
      // history("/apps-invoices-list");
      validation.resetForm();
    },
  });

  useEffect(() => {
    let totalCharge = 0;
    let totalAmount = 0;

    products.forEach((product) => {
      const productTotal = product.rate * product.count;
      totalAmount += productTotal;
    });

    if (totalAmount !== 0) {
      totalCharge = totalAmount;
    }

    // setTax(totalTax);
    // setDis(totalDis);
    setCharge(totalCharge);
    setGrandTotal(totalAmount);
  }, [products]);

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...products];
    updatedProducts[index] = {
      ...updatedProducts[index],
      [field]: value,
    };
    setProducts(updatedProducts);
  };

  const addProduct = () => {
    const newProduct = {
      name: "", // Add the product name or description here
      rate: 0,
      count: 0,
    };
    setProducts([...products, newProduct]);
  };

  const removeProduct = (index) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
  };


  // const downloadPDF = () => {
  //   const content = document.getElementById("invoice_form");
  
  //   // Log the content for debugging
  //   console.log("Original content:", content);
  
  //   const inputElements = content.querySelectorAll('input, textarea, select');
  
  //   // Create an array to store promises for each replacement
  //   const replacementPromises = [];
  
  //   inputElements.forEach((element) => {
  //     const span = document.createElement('span');
  //     span.classList.add('form-control', 'bg-light', 'border-0', 'product-line-price');
  
  //     span.textContent = element.value || 0;
  //     const replacementPromise = new Promise((resolve, reject) => {
  //       // Replace input element with span element
  //       element.parentNode.replaceChild(span, element);
  //       resolve(); // Resolve the promise after replacement
  //     });
  //     replacementPromises.push(replacementPromise);
  //   });
  
  //   // Wait for all replacements to be completed before proceeding
  //   Promise.all(replacementPromises)
  //     .then(() => {
  //       console.log("Replacement completed. Running html2canvas...");
  
  //       // Capture the entire scrollable content
  //       const scrollHeight = content.scrollHeight;
  //       const offset = 10;
  
  //       const captureScreenshots = (currentPosition) => {
  //         content.scrollTop = currentPosition;
  
  //         return html2canvas(content, {
  //           scrollY: -window.scrollY,
  //           windowHeight: window.innerHeight,
  //         }).then((canvas) => {
  //           document.body.appendChild(canvas);
  
  //           const imgData = canvas.toDataURL("image/png");
  
  //           const pdf = new jsPDF();
  //           const pdfWidth = pdf.internal.pageSize.getWidth();
  //           const pdfHeight = pdf.internal.pageSize.getHeight();
  
  //           const canvasWidth = content.offsetWidth;
  //           const canvasHeight = content.offsetHeight;
  //           const scaleFactor = pdfWidth / canvasWidth;
  //           const scaledHeight = canvasHeight * scaleFactor;
            
  //           pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, scaledHeight);
  
  //           currentPosition += window.innerHeight - offset;
  
  //           if (currentPosition < scrollHeight) {
  //             return captureScreenshots(currentPosition);
  //           }
  //         });
  //       };
  
  //       const pdf = new jsPDF(); // Declare pdf variable here
  
  //       captureScreenshots(0)
  //         .then(() => {
  //           // Revert the replacements
  //           // inputElements.forEach((element, index) => {
  //           //   element.parentNode.replaceChild(inputElements[index], content.querySelectorAll('.product-line-price')[index]);
  //           // });
  
  //           pdf.save("exported-form.pdf");
  //         })
  //         .catch((error) => {
  //           console.error("Error exporting to PDF:", error);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error("Error in replacement:", error);
  //     });
  // };
  
  const downloadPDF = () => {
    // let printContents = document.getElementById('invoice_form').innerHTML;

    // Create a link element for the print stylesheet
    const modalContent = document.querySelector("#invoice_form");

    const contentToExport = modalContent.cloneNode(true);
    const inputFields = contentToExport.querySelectorAll(
      "input[type='text'], input[type='number']"
    );
    inputFields.forEach((inputField) => {
      const inputFieldId = inputField.getAttribute("name");
      const inputFieldValue = inputField.value;
      const correspondingInputInClone = contentToExport.querySelector(
        `#${inputFieldId}`
      );
      if (correspondingInputInClone) {
        correspondingInputInClone.setAttribute("value", inputFieldValue);
        if (correspondingInputInClone.type === "text") {
          correspondingInputInClone.value = inputFieldValue;
        } else {
          correspondingInputInClone.textContent = inputFieldValue;
        }
      }
    });
    // printWindow.document.write("</body></html>");
    // printWindow.document.close();
    // printWindow.print();
    // printWindow.close();
    // let printStyleLink = document.createElement('link');
    // printStyleLink.rel = 'stylesheet';
    // printStyleLink.type = 'text/css';
    // printStyleLink.href = './invoices.css'; // Replace with the actual path
    
    // Append the link element to the head
    // document.head.appendChild(printStyleLink);
    
    // document.body.innerHTML = printContents;
    
    window.print();
    
    // document.body.innerHTML = originalContents;
    
    // Remove the print stylesheet link after printing
    // document.head.removeChild(printStyleLink);
    // const content = document.getElementById("invoice_form");

    // // Log the content for debugging
    // console.log("Original content:", content);

    // const inputElements = content.querySelectorAll('input, textarea, select');

    // // Store original values for input elements
    // const originalValues = Array.from(inputElements).map((element) => element.value || '');

    // // Create an array to store promises for each replacement
    // const replacementPromises = [];

    // inputElements.forEach((element, index) => {
    //     const span = document.createElement('span');
    //     span.classList.add('form-control', 'bg-light', 'border-0', 'product-line-price');
    //     span.textContent = originalValues[index];

    //     const replacementPromise = new Promise((resolve, reject) => {
    //         // Replace input element with span element
    //         element.parentNode.replaceChild(span, element);
    //         resolve(); // Resolve the promise after replacement
    //     });

    //     replacementPromises.push(replacementPromise);
    // });

    // // Wait for all replacements to be completed before proceeding
    // Promise.all(replacementPromises)
    //     .then(() => {
    //         console.log("Replacement completed. Running html2canvas...");

    //         // Capture the entire scrollable content
    //         return html2canvas(content, {
    //           height: document.getElementById('invoice_form').scrollHeight,
    //             scrollY: -window.scrollY,
    //             windowHeight: window.innerHeight,
    //             useCORS: true,
    //         });
    //     })
    //     .then((canvas) => {
    //         // Revert the replacements after capturing the screenshots
    //         inputElements.forEach((element, index) => {
    //             const originalInput = document.createElement('input');
    //             originalInput.type = 'text';
    //             originalInput.value = originalValues[index];
    //             // element.parentNode.replaceChild(originalInput, element);
    //         });

    //         // Save the PDF
    //         const pdf = new jsPDF();
    //         const pdfWidth = pdf.internal.pageSize.getWidth();
    //         const pdfHeight = pdf.internal.pageSize.getHeight();
    //         const scaleFactor = pdfWidth / canvas.width;
    //         const scaledHeight = canvas.height * scaleFactor;

    //         pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, pdfWidth, scaledHeight);
    //         pdf.save("exported-form.pdf");

    //         console.log("PDF creation completed.");
    //     })
    //     .catch((error) => {
    //         console.error("Error exporting to PDF:", error);
    //     });
};


// const downloadPDF = () => {
//   const content = document.getElementById("invoice_form");
//   const contentCopy = content.cloneNode(true);

//   // Log the content for debugging
//   console.log("Original content:", content);

//   const inputElements = contentCopy.querySelectorAll('input, textarea, select');

//   // Create an array to store promises for each replacement
//   const replacementPromises = [];

//   inputElements.forEach((element) => {
//     const span = document.createElement('span');
//     span.classList.add('form-control', 'bg-light', 'border-0', 'product-line-price');

//     span.textContent = element.value || 0;
//     const replacementPromise = new Promise((resolve, reject) => {
//       // Replace input element with span element
//       element.parentNode.replaceChild(span, element);
//       resolve(); // Resolve the promise after replacement
//     });
//     replacementPromises.push(replacementPromise);
//   });

//   // Wait for all replacements to be completed before proceeding
//   Promise.all(replacementPromises)
//     .then(() => {
//       console.log("Replacement completed. Running html2canvas...");

//       html2canvas(content)
//         .then((canvas) => {
//           // Rest of your code to create and save the PDF
//           const imgData = canvas.toDataURL("image/png");
//           const pdf = new jsPDF();
//           const pdfWidth = pdf.internal.pageSize.getWidth();
//           const pdfHeight = pdf.internal.pageSize.getHeight();

//           const canvasWidth = content.offsetWidth;
//           const canvasHeight = content.offsetHeight;
//           const scaleFactor = pdfWidth / canvasWidth;
//           const scaledHeight = canvasHeight * scaleFactor;
//           pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, scaledHeight);
//           pdf.save("exported-form.pdf");
//         })
//         .catch((error) => {
//           console.error("Error exporting to PDF:", error);
//         });
//     })
//     .catch((error) => {
//       console.error("Error in replacement:", error);
//     });
// };


  return (
    <>
      <Row className="justify-content-center" id="export-data">
        <Col xxl={9}>
          <Card>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              className="needs-validation"
              id="invoice_form"
            >
              <CardHeader className="border-bottom-dashed p-4">
                <div className="d-sm-flex">
                  <div className="flex-grow-1">
                    <img
                      src={Darklogo}
                      className="card-logo card-logo-dark "
                      alt="logo light"
                      height="60"
                    />
                    <div className="mt-sm-5 mt-4">
                      <h6 className="text-muted text-uppercase fw-semibold">
                        Address
                      </h6>
                      <p className="text-muted mb-1">
                        Level 2, Building 9 & 10, Central Market,
                      </p>
                      <p className="text-muted mb-1">
                        Punjabi Bagh, Delhi, India - 110026
                      </p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 mt-sm-0 mt-3">
                    <h6>
                      <span className="text-muted fw-normal">Email:</span>
                      admin@flodataanalytics.com
                    </h6>
                    <h6>
                      <span className="text-muted fw-normal">Website:</span>
                      <Link to="#" className="link-primary">
                        https://flodataanalytics.com/
                      </Link>
                    </h6>
                    <h6 className="">
                      <span className="text-muted fw-normal">Contact No:</span>
                      +91 93197 95757
                    </h6>
                    <h6 className="">
                      <span className="text-muted fw-normal">PAN:</span>
                      AAPFT1458C
                    </h6>
                    <h6 className="">
                      <span className="text-muted fw-normal">GSTN:</span>
                      07AAHFF3130F1ZV
                    </h6>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="p-4">
                <Row className="g-3">
                  <Col lg={4} sm={6}>
                    <Label for="invoicenoInput">Invoice No</Label>
                    <Input
                      type="text"
                      className="form-control bg-light border-0"
                      id="invoicenoInput"
                      name="invoiceId"
                      value={validation.values.invoiceId || ""}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      placeholder="Invoice No"
                      invalid={
                        validation.errors.invoiceId &&
                        validation.touched.invoiceId
                          ? true
                          : false
                      }
                    />
                    {validation.errors.invoiceId &&
                    validation.touched.invoiceId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.invoiceId}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col lg={4} sm={6}>
                    <div>
                      <Label for="date-field">Date</Label>
                      
                      <Input
                        name="date"
                        type="text"
                        id="date-field"
                        
                        className="form-control"
                        placeholder="Select date"
                        value={new Date().toLocaleDateString('en-US', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                        })}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col lg={4} sm={6}>
                    <div>
                      <Label for="totalamountInput">Total Amount</Label>
                      <Input
                        type="text"
                        className="form-control bg-light border-0"
                        id="totalamountInput"
                        placeholder="$0.00"
                        readOnly
                        value={"$" + charge.toFixed(2)}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardBody className="p-4 border-top border-top-dashed">
                <Row className="g-3">
                  <Col sm={6}>
                    <h6 className="text-muted text-uppercase fw-semibold mb-3">
                      INVOICE TO
                    </h6>
                    <p className="fw-medium mb-2">Phoenix Technologies Ltd</p>
                    <p className="text-muted mb-1">
                      752 N State St., Suite 316, Westerville,
                    </p>
                    <p className="text-muted mb-1">
                      Ohio, United States - 43082
                    </p>
                    <p className="text-muted mb-1">Phone: +1 (614) 423-6979</p>
                  </Col>
                </Row>
              </CardBody>
              <CardBody className="p-4">
                <div className="table-responsive">
                  <Table className="invoice-table table-borderless table-nowrap mb-0">
                    <thead className="align-middle">
                      <tr className="table-active">
                        <th scope="col" style={{ width: "50px" }}>
                          #
                        </th>
                        <th scope="col">Description</th>
                        <th scope="col" style={{ width: "120px" }}>
                          <div className="d-flex currency-select input-light align-items-center">
                            Rate ($)
                          </div>
                        </th>
                        <th scope="col" style={{ width: "120px" }}>
                          Quantity
                        </th>
                        <th
                          scope="col"
                          className="text-end"
                          style={{ width: "150px" }}
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="text-end"
                          style={{ width: "105px" }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody id="newlink">
                      {products.map((product, index) => (
                        <tr key={index} className="product">
                          <th scope="row" className="product-id">
                            {index + 1}
                          </th>
                          <td className="text-start">
                            <div className="mb-2">{product.name}</div>
                          </td>
                          <td>
                            <Input
                              type="number"
                              className="form-control product-price bg-light border-0"
                              placeholder="0.00"
                              value={product.rate > 0 ? product.rate : ""}
                              onChange={(e) =>
                                handleProductChange(
                                  index,
                                  "rate",
                                  e.target.value > 0 ? e.target.value : ""
                                )
                              }
                            />
                          </td>
                          <td>
                          <Input
                              type="number"
                              className="form-control product-price bg-light border-0"
                              placeholder="0.00"
                              value={product.count > 0 ? product.count : ''}
                              onChange={(e) =>
                                handleProductChange(
                                  index,
                                  "count",
                                  e.target.value > 0 ? e.target.value : ""
                                )
                              }
                            />
                            {/* <div className="input-step">
                              <button
                                type="button"
                                className="minus"
                                onClick={() =>
                                  handleProductChange(
                                    index,
                                    "count",
                                    Math.max(0, product.count - 1)
                                  )
                                }
                              >
                                –
                              </button>
                              <Input
                                type="number"
                                className="product-quantity"
                                min={0}
                                value={product.count > 0 ? product.count : 0}
                                readOnly
                              />
                              <button
                                type="button"
                                className="plus"
                                onClick={() =>
                                  handleProductChange(
                                    index,
                                    "count",
                                    product.count + 1
                                  )
                                }
                              >
                                +
                              </button>
                            </div> */}
                          </td>
                          <td className="text-end">
                            <div>
                              <Input
                                type="text"
                                className="form-control bg-light border-0 product-line-price"
                                placeholder="$0.00"
                                value={`$${product.rate * product.count}`}
                                readOnly
                              />
                            </div>
                          </td>
                          <td className="product-removal">
                            <Link
                              to="#"
                              className="btn btn-success"
                              onClick={() => removeProduct(index)}
                            >
                              Delete
                            </Link>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan={3} >
                          <label >Total Amount</label>
                          <Input
                            type="text"
                            className="form-control bg-light border-0"
                            id="cart-total"
                            placeholder="$0.00"
                            readOnly
                            value={"$" + charge.toFixed(2)}
                          />
                        </td>
                      </tr>
                    </tbody>

                  </Table>
                </div>
                <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                  <Button
                    className="btn btn-primary"
                    onClick={downloadPDF}
                  >
                    <i className="ri-download-2-line align-bottom me-1"></i>
                    Download Invoice
                  </Button>
                </div>
              </CardBody>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InvoiceDetails;
