import {React,useState} from "react";
import ModalComponent  from "./ShowDataModal"; 
import SvgMap from "./SvgMap";

function USMapWithSVG({selectedFilters,activeCategry,setCategories, settotalWebsiteCount,siteCounts}) {
  const [selectedState, setSelectedState] = useState(null);
  const [open, setOpen] = useState(false);
  const [colors, updateColors] = useState({});
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ width: "80vw", overflow: "hidden" }}
    >
      
      <SvgMap colors={colors} updateColors={updateColors} setSelectedState={setSelectedState} siteCounts={siteCounts} /> 
      
      {selectedState && <ModalComponent selectedFilters={selectedFilters} state={selectedState} activeCategry={activeCategry} open={open} setOpen={setOpen} setSelectedState={setSelectedState} setCategories={setCategories} settotalWebsiteCount={settotalWebsiteCount} />}
    </div>
  );
}

export default USMapWithSVG;
