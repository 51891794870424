import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Card, Col, Input, Label, Form, Row ,Modal,ModalBody} from "reactstrap";

const ManagePasswordPage = () => {
  const { updateUserPassword } = useAuth();
  const [passwordData, setPasswordData] = useState({
    oldpasswordInput: "",
    newpasswordInput: "",
    confirmpasswordInput: "",
  });
  const [passwordMatch, setPasswordMatch] = useState(true); // To track password match
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);
  // Function to handle password input changes
  const handlePasswordChange = (e) => {
    const { id, value } = e.target;
    setPasswordData({
      ...passwordData,
      [id]: value,
    });
    if (id === "newpasswordInput") {
      setPasswordMatch(value === passwordData.confirmpasswordInput);
    } else if (id === "confirmpasswordInput") {
      setPasswordMatch(passwordData.newpasswordInput === value);
    }
  };
  const ChangePassword = async (e) => {
    let change_status = await updateUserPassword(passwordData.oldpasswordInput,
      passwordData.confirmpasswordInput
    );
    if(change_status){
      setUpdateStatus(true)
      toggleErrorModal()
    }
    else{
      setUpdateStatus(false)
      toggleErrorModal()
    }
  };
  return (
    <>
      <Col>
        <Card>
          <Row className="m-2">
            <Col className="mt-2">
              <Form>
                <Row className="g-2">
                  <Col lg={4}>
                    <div>
                      <Label htmlFor="oldpasswordInput" className="form-label">
                        Old Password*
                      </Label>
                      <Input
                        type="password"
                        className="form-control"
                        id="oldpasswordInput"
                        placeholder="Enter current password"
                        onChange={handlePasswordChange}
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div>
                      <Label htmlFor="newpasswordInput" className="form-label">
                        New Password*
                      </Label>
                      <Input
                        type="password"
                        className={`form-control ${
                          !passwordMatch && "is-invalid"
                        }`}
                        id="newpasswordInput"
                        placeholder="Enter new password"
                        onChange={handlePasswordChange}
                      />
                      {!passwordMatch && (
                        <div className="invalid-feedback">
                          Passwords do not match
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div>
                      <Label
                        htmlFor="confirmpasswordInput"
                        className="form-label"
                      >
                        Confirm Password*
                      </Label>
                      <Input
                        type="password"
                        className={`form-control ${
                          !passwordMatch && "is-invalid"
                        }`}
                        id="confirmpasswordInput"
                        placeholder="Confirm password"
                        onChange={handlePasswordChange}
                      />
                      {!passwordMatch && (
                        <div className="invalid-feedback">
                          Passwords do not match
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={ChangePassword}
                      >
                        Change Password
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
      <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0  status-popup">
            <div
              className={
                updateStatus
                  ? "alert alert-success alert-dismissible  show m-0"
                  : "alert alert-danger alert-dismissible  show m-0"
              }
              role="alert"
            >
              {updateStatus ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: "Password updated",
                  }}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: "Something went wrong" }}
                />
              )}
              <button
                type="button"
                className="btn-close alrt-clse"
                onClick={toggleErrorModal}
                aria-label="Close"
              ></button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default ManagePasswordPage;
