import "./style.css";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Button,
  Col,
  Input,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useApi } from "../../context/ApiContext";
var row_ids = [];
function titleCase(str) {
  return str
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
const AllUsers = ({ rows, loading, setRows, jurisdictions }) => {
  // var row_data_juridictions = [];
  const Api = useApi();
  const [activeRows, setActiveRows] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [show, changeShow] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  // row_data_juridictions = rows.map((row) => row.jurisdiction).flat();

  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

  const UpdateButtonStatus = (e) => {
    let checkbox_status = e.target.checked;
    let checkbox_id = e.target.id;
    if (checkbox_status === true) {
      const updatedRows = [...activeRows, checkbox_id];
      row_ids.push(checkbox_id);
      changeShow(true);
      setActiveRows(updatedRows);
    } else {
      const updatedRows = activeRows.filter((id) => id !== checkbox_id);
      row_ids.pop(checkbox_id);
      setActiveRows(updatedRows);
    }
    if (row_ids.length === 0) {
      setActiveRows([]);
      changeShow(false);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {}, [activeRows]);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleDelete = async () => {
    setIsDeleting(true);
    // setIsDeleting(false);
    let data = { request_for: "delete_users", row_ids: row_ids };
    Api
      .userManagement(data)
      .then((data) => {
        if (data.rows) {
          let rows = data.rows;
          setRows(rows);
          toggleModal();
          row_ids = [];
          setActiveRows([]);
          setIsDeleting(false);
          changeShow(false);
        } else {
          toggleErrorModal();
          toggleModal();
        }
      })
      .catch((error) => {
        console.error("Error deleting:", error);
      })
      .finally(() => {
        setIsDeleting(false); // Reset the deleting state
      });
  };
  const handleRemovejurisdiction = (event, row, col, value) => {
    event.preventDefault();
    const updatedRowData = [...rows];
    updatedRowData[row]["custom_claims"]["jurisdictions"] =
      updatedRowData[row]["custom_claims"]["jurisdictions"] &&
      updatedRowData[row]["custom_claims"]["jurisdictions"].filter(
        (item) => item !== value
      );
    const row_id =
      event.target.parentElement.parentElement.parentElement.parentElement
        .dataset.id;

    const column = col;
    let cell_value = updatedRowData[row]["custom_claims"]["jurisdictions"];
    let data = {
      request_for: "edit_jurisdictions",
      params: [column, row_id, cell_value],
    };
    Api.userManagement(data).then((data) => {
      let rows = data.rows;
      setRows(
        rows.sort((a, b) => a.display_name.localeCompare(b.display_name))
      );
      setRows(rows);
      // setActiveRows([]);
      // changeShow(false);
    });
  };
  const handleDrop = (event, row, id, col) => {
    event.preventDefault();
    const value = event.dataTransfer.getData("text");
    const updatedRowData = [...rows];
    const row_id = id;
    const column = col;
    if (
      updatedRowData[row]["custom_claims"]["jurisdictions"] &&
      !updatedRowData[row]["custom_claims"]["jurisdictions"].includes(value)
    ) {
      updatedRowData[row]["custom_claims"]["jurisdictions"] &&
        !updatedRowData[row]["custom_claims"]["jurisdictions"].includes(
          value
        ) &&
        updatedRowData[row]["custom_claims"]["jurisdictions"].push(value);
      updatedRowData[row]["custom_claims"]["jurisdictions"] = updatedRowData[
        row
      ]["custom_claims"]["jurisdictions"]
        ? updatedRowData[row]["custom_claims"]["jurisdictions"]
        : value;
      let cell_value = updatedRowData[row]["custom_claims"]["jurisdictions"];
      let data = {
        request_for: "edit_jurisdictions",
        params: [column, row_id, cell_value],
      };

      Api.userManagement(data).then((data) => {
        let rows = data.rows;
        setRows(
          rows.sort((a, b) => a.display_name.localeCompare(b.display_name))
        );
        setRows(rows);
        // setActiveRows([]);
        // changeShow(false);
      });
    } else {
      setActiveRows([]);
      changeShow(false);
    }
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>Users</title>
      </MetaTags>
      <button
        style={{ display: show ? "flex" : "none" }}
        className="delete-btn btn btn-sm btn-danger btn-label"
        onClick={toggleModal}
      >
        <i className={` btn-danger bx bx-trash label-icon align-middle`}></i>
        <div className="delete-btn-txt">Delete</div>
      </button>
      <div className="card" id="drag-source" style={{ marginTop: "1rem" }}>
        {/* <div
          className="px-2 mt-2"
        >
          <label>Jurisdictions </label>  
          <span><i> (drag and drop into <b>Jurisdiction</b> column)</i>
          </span>
        </div> */}
        <div>
          {jurisdictions &&
            jurisdictions.map((val, key) => {
              return (
                <span
                  draggable={activeRows.length > 0 ? "true" : "false"}
                  key={key}
                  onDragStart={(e) => {
                    e.dataTransfer.setData("text/plain", e.target.textContent);
                  }}
                  style={{ fontSize: 12, cursor: "pointer" }}
                  className={"badge badge-soft-danger p-2 m-2"}
                >
                  {val}
                </span>
              );
            })}
        </div>
      </div>
      <div id="report-table-container">
        <div
          className="table-responsive"
          style={{ maxHeight: 400, overflowY: "auto" }}
        >
          <table
            id="report-table"
            className="table  table-nowrap my-0 py-0 all-users"
          >
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Jurisdictions</th>
              </tr>
            </thead>
            {rows.length > 0 ? (
              <tbody>
                {rows.map((arr, key) => (
                  <tr
                    style={{ textAlign: "center" }}
                    data-id={arr.uid}
                    key={key}
                  >
                    <td>
                      <div
                        className="form-check-success"
                        style={{ textAlign: "center" }}
                      >
                        <Input
                          onChange={arr.custom_claims.role !== 'admin' && UpdateButtonStatus}
                          className="form-check-input"
                          type="checkbox"
                          style={{ border: "1px solid #bbbbbb" }}
                          id={arr.uid}
                          disabled = {arr.custom_claims.role === 'admin' ? true : false}
                          checked={ arr.custom_claims.role !== 'admin' && activeRows.includes(arr.uid)}
                        />
                      </div>
                    </td>
                    <td data-col="name" data-row={key} data-id={arr.id}>
                      {arr.display_name}
                    </td>
                    <td data-col="server_name" data-row={key} data-id={arr.id}>
                      {arr.email}
                    </td>
                    <td data-col="role" data-row={key} data-id={arr.id}>
                      {titleCase(arr.custom_claims.role)}
                    </td>
                    <td
                      data-col="jurisdiction"
                      data-row={key}
                      data-id={arr.id}
                      onDrop={(e) =>
                        activeRows.includes(arr.uid) && handleDrop(e, key, arr.uid, "jurisdiction")
                      }
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          minHeight: 50,
                          gap: "1rem",
                        }}
                      >
                        {arr.custom_claims.jurisdictions.sort().map((i, e) => {
                          return (
                            <span
                              key={e}
                              style={{
                                fontSize: 12,
                                cursor: "pointer",
                                height: 37,
                                display: "flex",
                                alignItems: "center",
                                gap:5
                              }}
                              className={"badge badge-soft-secondary p-2 my-1 "}
                            >
                              {i}
                              {activeRows.includes(arr.uid) ? (
                                <Button
                                  className="jurisdiction-badge btn-close close-btn-users"
                                  style={{ fontWeight: "bold" ,fontSize:10}}
                                  onClick={(event) =>
                                    handleRemovejurisdiction(
                                      event,
                                      key,
                                      "jurisdiction",
                                      i
                                    )
                                  }
                                ></Button>
                              ) : (
                                ""
                              )}
                            </span>
                          );
                        })}
                      </div>
                    </td>
                    {/* <td
                      data-col="jurisdictions"
                      data-row={key}
                      style={{"gap":"20px","display":"flex" ,"justifyContent":"center"}}
                    >
                      {arr.custom_claims.jurisdictions.map((i, e) => {
                        return (
                          <span
                            key={e}
                            className={"badge badge-soft-secondary p-2 my-1 "}
                          >
                            {i}
                          </span>
                        );
                      })}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={5}>
                      <div className="loader-container">
                        <div className="loader"></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td style={{ fontWeight: "400", textAlign: "center" }}>
                      No data available.
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
        <Modal isOpen={isOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>CONFIRM DELETE</ModalHeader>
          <ModalBody>Are you sure you want to delete this item?</ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              disabled={isDeleting}
              onClick={toggleModal}
            >
              Cancel
            </Button>{" "}
            <Button color="danger" disabled={isDeleting} onClick={handleDelete}>
              {isDeleting ? "Deleting..." : "Delete"}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
          <div className="modal-content border-0">
            <ModalBody className="p-0  status-popup">
              <div
                className={"alert alert-danger alert-dismissible  show m-0"}
                role="alert"
              >
                <div
                  dangerouslySetInnerHTML={{ __html: "Something Went Wrong" }}
                />
                <button
                  type="button"
                  className="btn-close alrt-clse"
                  onClick={toggleErrorModal}
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default AllUsers;
