import "./captcha.css";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useApi } from "../../context/ApiContext";
import {
  Button,
  Col,
  Input,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import * as XLSX from "xlsx";
var row_ids = [];
export default function ReportTable({
  rowdata,
  setRows,
  jurisdictions,
  loading,
}) {
  const Api = useApi();
  const { userIsAdmin, userIsDeveloper, availableJurisdictions } = useAuth();
  var row_data_juridictions = [];
  var api_keys = [];
  const [show, changeShow] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [modal_togFirst, setmodal_togFirst] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [updatedcredsstatus, setUpdatedCredsStatus] = useState(false);
  const [activeRows, setActiveRows] = useState([]);
  const [tdmodalOpen, setTdmodalOpen] = useState(false);
  const [balancemodalData, setBalanceModalData] = useState([]);
  const [companyname, setCompanyName] = useState("");
  const [showApiKeys, setShowApiKeys] = useState({});
  row_data_juridictions = rowdata.map((row) => row.jurisdictions).flat();
  api_keys = rowdata.map((row) => row.apikey).flat();
  const UpdateButtonStatus = (e) => {
    let checkbox_status = e.target.checked;
    let checkbox_id = e.target.id;
    if (checkbox_status === true) {
      const updatedRows = [...activeRows, parseInt(checkbox_id)];
      row_ids.push(parseInt(checkbox_id));
      changeShow(true);
      setActiveRows(updatedRows);
    } else {
      const updatedRows = activeRows.filter((id) => id !== parseInt(checkbox_id));
      row_ids = activeRows.filter((id) => id !== parseInt(checkbox_id));
      setActiveRows(updatedRows);
    }
    if (row_ids.length === 0) {
      setActiveRows([]);
      changeShow(false);
    }
  };
  useEffect(() => {}, [activeRows, balancemodalData, companyname]);

  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

  const addCaptcha = () => {
    let company_name = document.getElementById("companyinput").value;
    let apikey = document.getElementById("apikeyinput").value;
    let owner = document.getElementById("ownerinput").value;

    if (!api_keys.includes(apikey)) {
      let new_captcha = {
        company_name: company_name,
        apikey: apikey,
        owner: owner,
      };
      let data = {
        request_for: "add_captcha",
        captcha_details: new_captcha,
      };
      Api.captchaCrudOps(data).then((data) => {
        if (data.rows) {
          let rows = data.rows;
          setRows(rows);
          setmodal_togFirst(false);
        } else {
          toggleErrorModal();
          setmodal_togFirst(false);
        }
      });
    } else {
      setmodal_togFirst(false);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    setIsDeleting(false);
    let data = { request_for: "delete_captcha", row_ids: row_ids };
    Api.captchaCrudOps(data).then((data) => {
      let rows = data.rows;
      setRows(rows);
      toggleModal();
      row_ids = [];
      setActiveRows([]);
      const checkboxes = document.querySelectorAll(
        '.form-check-success input[type="checkbox"]'
      );
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    });
  };

  const handleRemovejurisdiction = (event, row, col, value) => {
    event.preventDefault();
    const updatedRowData = [...rowdata];
    updatedRowData[row][col] =
      updatedRowData[row][col] &&
      updatedRowData[row][col].filter((item) => item !== value);
    const row_id =
      event.target.parentElement.parentElement.parentElement.dataset.id;
    const column = col;
    let cell_value = updatedRowData[row][col];
    let data = {
      request_for: "update_jurisdiction",
      params: [column, row_id, cell_value],
    };
    Api.captchaCrudOps(data).then((data) => {
      let rows = data.rows;
      setRows(rows);
    });
  };
  const balanceHistory = (event) => {
    let company = event.target.dataset.company;
    let apikey = event.target.dataset.api;

    let data = {
      request_for: "company_data",
      company_name: company,
      api: apikey,
    };
    Api.captchaCrudOps(data).then((data) => {
      setBalanceModalData(data.data);
      setCompanyName(data.company);
      setTdmodalOpen(true);
    });
  };
  const handleExport = () => {
    const formattedData = rowdata.map(
      ({ company_name, jurisdictions, api_owner }) => ({
        company_name,
        api_owner,
        jurisdictions: Array.isArray(jurisdictions)
          ? jurisdictions.join(", ")
          : jurisdictions,
      })
    );
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Captcha Details");
    XLSX.writeFile(wb, "captcha_details.xlsx");
  };
  const handleDrop = (event, row, id, col) => {
    event.preventDefault();
    const value = event.dataTransfer.getData("text");
    const updatedRowData = [...rowdata];
    const row_id = id;
    const column = col;
    if (updatedRowData[row][col] && !updatedRowData[row][col].includes(value)) {
      updatedRowData[row][col] && updatedRowData[row][col].push(value);
      updatedRowData[row][col] = updatedRowData[row][col]
        ? updatedRowData[row][col]
        : value;
      let cell_value = updatedRowData[row][col];
      let data = {
        request_for: "update_jurisdiction",
        params: [column, row_id, cell_value],
      };
      Api.captchaCrudOps(data).then((data) => {
        let rows = data.rows;
        setRows(rows);
      });
    }
  };
  const toggleApiKeyVisibility = (index) => {
    setShowApiKeys({
      ...showApiKeys,
      [index]: !showApiKeys[index],
    });
  };
  return (
    <>
      <div className="d-flex flex-row-reverse justify-content-between align-items-center pt-4 pb-2 gap-4">
        <div className="d-flex gap-2 d-flex-align-items-center">
          {userIsAdmin ? (
            <button
              style={{ visibility: show ? "visible" : "hidden" }}
              className="delete-btn btn btn-sm btn-danger btn-label"
              onClick={toggleModal}
            >
              <i
                className={` btn-danger bx bx-trash label-icon align-middle`}
              ></i>
              <div className="delete-btn-txt">Delete</div>
            </button>
          ) : (
            ""
          )}
          <button className=" btn btn-sm btn-primary " onClick={handleExport}>
            <div
              className="steps-btn-txt d-flex align-items-center"
              style={{ gap: "2px" }}
            >
              Export
            </div>
          </button>
        </div>
        {userIsAdmin ? (
          <>
            <button
              className="add-btn btn btn-sm btn-success btn-label"
              onClick={() => setmodal_togFirst(!modal_togFirst)}
            >
              <i
                className={` btn-success bx bx-plus label-icon align-middle`}
              ></i>
              <div className="delete-btn-txt">Add Captcha</div>
            </button>
          </>
        ) : (
          ""
        )}

        <Modal
          modalTransition={{ timeout: 0 }}
          isOpen={modal_togFirst}
          toggle={() => {
            setmodal_togFirst(!modal_togFirst);
          }}
          id="firstmodal"
          centered
        >
          <ModalHeader className="p-5" style={{ display: "flex" }}>
            <Col style={{ display: "flex" }}>
              <h5
                style={{ width: "96%", display: "flex" }}
                className="modal-title"
                id="exampleModalToggleLabel"
              >
                Add new captcha
              </h5>
              <Button
                style={{ paddingRight: 0 }}
                type="button"
                className="btn-close"
                onClick={() => {
                  setmodal_togFirst(false);
                }}
                aria-label="Close"
              ></Button>
            </Col>
          </ModalHeader>
          <ModalBody className="p-5">
            <div className="row g-3">
              <Col lg={12}>
                <div>
                  <label htmlFor="companyinput" className="form-label">
                    Company Name
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="companyinput"
                    placeholder="Name of company"
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <label htmlFor="apikeyinput" className="form-label">
                    API Key
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="apikeyinput"
                    placeholder="API key"
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <label htmlFor="companyinput" className="form-label">
                    Owner Name
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="ownerinput"
                    placeholder="Name of owner"
                  />
                </div>
              </Col>
            </div>
          </ModalBody>
          <ModalFooter style={{ marginRight: "4%" }}>
            <div className="row g-3">
              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    className={`cart-btn btn btn-md btn-warning btn-label`}
                    onClick={() => {
                      addCaptcha();
                    }}
                  >
                    <i className={`mdi mdi-server label-icon align-middle`}></i>
                    <div className="cart-btn-txt">Add</div>
                  </button>
                </div>
              </Col>
            </div>
          </ModalFooter>
        </Modal>
        <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
          <div className="modal-content border-0">
            <ModalBody className="p-0  status-popup">
              <div
                className={
                  updatedcredsstatus
                    ? "alert alert-success alert-dismissible  show m-0"
                    : "alert alert-danger alert-dismissible  show m-0"
                }
                role="alert"
              >
                {updatedcredsstatus ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: "Details has been Added",
                    }}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{ __html: "Something Went Wrong" }}
                  />
                )}
                <button
                  type="button"
                  className="btn-close alrt-clse"
                  onClick={toggleErrorModal}
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
          </div>
        </Modal>
        <Modal isOpen={isOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Confirm Delete</ModalHeader>
          <ModalBody>Are you sure you want to delete this item?</ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              disabled={isDeleting}
              onClick={toggleModal}
            >
              Cancel
            </Button>{" "}
            <Button color="danger" disabled={isDeleting} onClick={handleDelete}>
              {isDeleting ? "Deleting..." : "Delete"}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={tdmodalOpen} toggle={() => setTdmodalOpen(false)}>
          <ModalHeader toggle={() => setTdmodalOpen(false)}>
            Balance history for {companyname}
          </ModalHeader>
          <ModalBody>
            <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
              <table
                id="balance-table"
                className="table table-bordered table-nowrap mb-0 "
              >
                <thead
                  className="sticky-top"
                  style={{ top: 0, background: "#dfdfdf", zIndex: 1 }}
                >
                  <tr>
                    <th>Date</th>
                    <th>Balance</th>
                    <th>Difference</th>
                  </tr>
                </thead>
                <tbody>
                  {balancemodalData ? (
                    balancemodalData.map((arr, key) => {
                      return (
                        <tr style={{ textAlign: "center" }} key={key}>
                          <td>{arr.date}</td>
                          <td>
                            {arr.balance != "" &&
                              arr.balance != undefined &&
                              arr.balance != null &&
                              parseFloat(arr.balance).toFixed(4)}
                          </td>
                          <td>
                            {arr.balance_difference !== "" &&
                              arr.balance_difference !== undefined &&
                              arr.balance_difference !== null &&
                              (arr.balance_difference > 0
                                ? "Recharged (" +
                                  parseFloat(arr.balance_difference).toFixed(
                                    4
                                  ) +
                                  ")"
                                : parseFloat(arr.balance_difference).toFixed(
                                    4
                                  ))}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td style={{ fontWeight: "400", textAlign: "center" }}>
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={() => setTdmodalOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div
        className="card"
        id="drag-source"
        style={{
          display: jurisdictions.includes(row_data_juridictions)
            ? "none"
            : "flex",
        }}
      >
        <div>
          {jurisdictions &&
            jurisdictions.sort().map((val, key) => {
              if (!row_data_juridictions.includes(val)) {
                return (
                  <span
                    draggable={activeRows.length > 0 ? "true" : "false"}
                    key={key}
                    onDragStart={(e) => {
                      e.dataTransfer.setData(
                        "text/plain",
                        e.target.textContent
                      );
                    }}
                    style={{ fontSize: 12, cursor: "pointer" }}
                    className={"badge badge-soft-danger p-2 m-2"}
                  >
                    {val}
                  </span>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
      <div id="report-table-container">
        <div
          className="table-responsive"
          style={{ maxHeight: 400, overflowY: "auto" }}
        >
          <table
            id="report-table"
            className="table table-nowrap my-0 py-0 all-users"
          >
            <thead>
              {rowdata.length > 0 ? (
                <tr>
                  <th></th>
                  <th>Company Name</th>
                  <th>Owner</th>
                  <th>API Key</th>
                  <th>Balance</th>
                  <th>Jurisdictions</th>
                </tr>
              ) : (
                <tr>
                  <th></th>
                </tr>
              )}
            </thead>
            {rowdata.length > 0 ? (
              <tbody>
                {rowdata.map((arr, key) => (
                  <tr style={{ textAlign: "center" }} key={key}>
                    <td>
                      <div
                        className="form-check-success"
                        style={{ textAlign: "center" }}
                      >
                        {userIsAdmin ? (
                          <Input
                            onChange={UpdateButtonStatus}
                            className="form-check-input"
                            type="checkbox"
                            style={{ border: "1px solid #bbbbbb" }}
                            id={arr.id}
                          />
                        ) : (
                          key + 1
                        )}
                      </div>
                    </td>
                    <td data-col="company_name" data-row={key} data-id={arr.id}>
                      {arr.company_name}
                    </td>
                    <td data-col="company_name" data-row={key} data-id={arr.id}>
                      {arr.api_owner ? arr.api_owner : "-"}
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        {showApiKeys[key] ? (
                          <span>{arr.apikey}</span>
                        ) : (
                          <span style={{ marginTop: 2 }}>{"*".repeat(20)}</span>
                        )}
                        <button
                          onClick={
                            userIsAdmin || userIsDeveloper
                              ? () => toggleApiKeyVisibility(key)
                              : null
                          }
                          disabled={!(userIsAdmin || userIsDeveloper)}
                          className={
                            showApiKeys[key]
                              ? "btn mdi mdi-eye-off py-0"
                              : "btn mdi mdi-eye p y-0"
                          }
                        ></button>
                      </div>
                    </td>
                    <td
                      data-col="balance"
                      data-row={key}
                      data-id={arr.id}
                      data-company={arr.company_name}
                      data-api={arr.apikey}
                      style={{ cursor: "pointer" }}
                      onClick={balanceHistory}
                    >
                      {arr.balance !== '' && arr.balance !== null && arr.balance !== undefined ? parseFloat(arr.balance).toFixed(2): "-"}
                    </td>
                    <td
                      data-col="jurisdictions"
                      data-row={key}
                      data-id={arr.id}
                      onDrop={(e) =>
                        handleDrop(e, key, arr.id, "jurisdictions")
                      }
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          minHeight: 50,
                          gap: "1rem",
                        }}
                      >
                        {arr.jurisdictions
                          ? arr.jurisdictions.sort().map((i, e) => {
                              return (
                                <span
                                  key={e}
                                  style={{
                                    fontSize: 12,
                                    cursor: "pointer",
                                    height: 37,
                                    gap: 5,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className={
                                    "badge badge-soft-secondary p-2 my-1 "
                                  }
                                >
                                  {i}
                                  {activeRows.includes(arr.id) ? (
                                    <Button
                                      className="jurisdiction-badge  btn-close close-btn-users"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 10,
                                      }}
                                      onClick={(event) =>
                                        handleRemovejurisdiction(
                                          event,
                                          key,
                                          "jurisdictions",
                                          i
                                        )
                                      }
                                    >
                                      <i className="fa fa-close"></i>
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              );
                            })
                          : ""}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                {loading ? (
                  <tr>
                    <td>
                      <div className="loader-container">
                        <div className="loader"></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td style={{ fontWeight: "400", textAlign: "center" }}>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
