import React from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableFilters from "./TableFilters";
// Import Table Data
import { FixedHeaderExample } from "./GridTablesData";

const GridTables = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Monitoring Dashboard</title>
        </MetaTags>
        <Container fluid>
          <Row className="page-name">
            <BreadCrumb
              // style={{ marginLeft: "-10px" }}
              title="Monitoring"
              pageTitle="Dashboards"
            />
          </Row>
          <Row>
            <Col lg={12}>
              <TableFilters />
              <div
                style={{
                  boxShadow: "0 2px 8px 0 rgb(99 99 99 / 20%)",
                  background: "white",
                  borderRadius: "0.8rem",
                }}
                id="table-fixed-header"
              >
                <FixedHeaderExample />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GridTables;
