import React , { useEffect, useState } from "react";

import MetaTags from "react-meta-tags";
import AllUsers from "./UsersList";
import { Container } from "reactstrap";
import { useApi } from "../../context/ApiContext"; 
import BreadCrumb from "../../Components/Common/BreadCrumb";

const Users = () => {
  const Api = useApi();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jurisdictions, setjurisdictions] = useState([]);

  useEffect(() => {
    Api.getUsersList().then((data) => {
    setRows(data.users.sort((a, b) => a.display_name.localeCompare(b.display_name)));
    setjurisdictions(data.jurisdictions);
    setLoading(false)
  })},[])

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title> Show Users</title>
          </MetaTags>
          <BreadCrumb title="Users" pageTitle="Dashboards" />
          <AllUsers rows={rows} loading={loading} setRows={setRows} jurisdictions={jurisdictions}/>
        </Container>
      </div>
    </>
  );
};

export default Users;
