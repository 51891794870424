import "./jurisdictions.css";
import React, { useState } from "react";
import { useApi } from "../../context/ApiContext";
import { useAuth } from "../../context/AuthContext";
import {
  Input,
  Modal,
  Label,
  ModalBody,
  Row,
  Card,
  Col,
  CardBody,
} from "reactstrap";
import CountUp from "react-countup";
import ModalComponent from "./AddJurisdictionModal";
export default function ReportTable({ rowdata, setRows, loading,jurisdictionCounts,setJurisdictionCounts }) {
  const Api = useApi()
  const { userIsAdmin } = useAuth()
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [updatedcredsstatus, setUpdatedCredsStatus] = useState(false);
  const [open,isOpen] = useState(false)
  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

  const changeStatus = (jurisdiction, e) => {
    let data = {
      request_for: "edit_jurisdictions",
      jurisdiction: jurisdiction,
      status: e.target.checked ? 1 : 0,
    };

    Api.changeJurisdictioStatus(data).then((data) => {
      if (data.jurisdictions) {
        let rows = data.jurisdictions;
        let jurisdiction_counts = data.jurisdiction_counts
        setRows(rows);
        setJurisdictionCounts(jurisdiction_counts)
      } else {
        toggleErrorModal();
        setUpdatedCredsStatus(false);
      }
    });
  };
  return (
    <>
      <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0  status-popup">
            <div
              className={
                updatedcredsstatus
                  ? "alert alert-success alert-dismissible  show m-0"
                  : "alert alert-danger alert-dismissible  show m-0"
              }
              role="alert"
            >
              {updatedcredsstatus ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: "Successfully changed status",
                  }}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: "Something Went Wrong" }}
                />
              )}
              <button
                type="button"
                className="btn-close alrt-clse"
                onClick={toggleErrorModal}
                aria-label="Close"
              ></button>
            </div>
          </ModalBody>
        </div>
      </Modal>

      <Row>
        <Col xl={12}>
          <Card className="crm-widget">
            <CardBody className="p-0">
              <Row className="row-cols-md-3 row-cols-1">
                {(jurisdictionCounts || []).map((item, key) => (
                  <Col
                    className={item.id === 3 ? "col-lg" : "col-lg border-end"}
                    key={key}
                  >
                    <div className="mt-3 mt-md-0 py-4 px-3">
                      <h5 className="text-muted text-uppercase fs-13">
                        {item.label}
                      </h5>
                      <div className="d-flex align-items-center">
                        
                        <div className="flex-grow-1 ms-3">
                          <h2 className="mb-0">
                            <span className="counter-value">
                              <CountUp
                                start={0}
                                separator={item.separator}
                                end={item.counter}
                                duration={1}
                              />
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <div className="d-flex flex-row justify-content-between mb-2 align-items-center pb-2 gap-4">
        {userIsAdmin ? (
          <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <button
              className="add-btn btn btn-sm btn-success btn-label"
              onClick={() => isOpen(!open)}
            >
              <i
                className={` btn-success bx bx-plus label-icon align-middle`}
              ></i>
              <div className="add-juric-btn-txt">Add Jurisdiction</div>
            </button>
          </div>
        ) : (
          ""
          )}
      </div>
       {open && <ModalComponent open={open} isOpen={isOpen} setRows={setRows} setJurisdictionCounts={setJurisdictionCounts} />}
      <div id="report-table-container">
        <div
          className="table-responsive"
          style={{ maxHeight: 400, overflowY: "auto" }}
        >
          <table
            id="report-table"
            className="table table-nowrap my-0 py-0 all-users"
          >
            <thead>
              {rowdata.length > 0 ? (
                <tr>
                  <th>Jurisdictions</th>
                  <th>Active / Inactive</th>
                </tr>
              ) : (
                <tr>
                  <th></th>
                </tr>
              )}
            </thead>
            {rowdata.length > 0 ? (
              <tbody>
                {rowdata.map((arr, key) => (
                  <tr style={{ textAlign: "center" }} key={key}>
                    <td data-col="jurisdiction" data-row={key} data-id={arr.id}>
                      {arr.jurisdiction}
                    </td>
                    <td data-col="status" data-row={key} data-id={arr.id}>
                      <div className="form-check form-switch form-switch-right form-switch-md">
                        <Input
                          className="form-check-input code-switcher"
                          type="checkbox"
                          checked={arr.active === 1}
                          onChange={(e) => {
                            changeStatus(arr.jurisdiction, e);
                          }}
                        />
                      </div>
                      {/* <FormGroup switch  
                        style={{ display: "flex", justifyContent: "center" ,gap:"10px",alignContent:'center',alignItems:'center'}}
                      >
                        <Input
                          type="switch"
                          checked={arr.active === 1}
                          onChange={(e) => {
                            changeStatus(arr.jurisdiction, e);
                          }}
                          />
                      </FormGroup> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                {loading ? (
                  <tr>
                    <td>
                      <div className="loader-container">
                        <div className="loader"></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td style={{ fontWeight: "400", textAlign: "center" }}>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
