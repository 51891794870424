import React, { useEffect, useRef } from "react";
import { Label } from "reactstrap";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import flatpickr from "flatpickr"; // Import Flatpickr

import "flatpickr/dist/flatpickr.min.css"; // Import Flatpickr CSS

function MonthPicker({ setMonthFilter, monthFilter }) {
  const inputRef = useRef(null);
  let flatpickrInstance = null; // Store the Flatpickr instance

  useEffect(() => {
    const options = {
      plugins: [
        new monthSelectPlugin({
          dateFormat: "m-Y",
        }),
      ],
      maxDate: new Date(),
      defaultDate: new Date(),
      disableMobile:true,
      onChange: function (selectedDates, dateStr) {
        const selectedDate = selectedDates[0];
        const yearstring = selectedDate.getFullYear();
        const monthstring = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
        setMonthFilter((oldValue) => {
          if (oldValue === null)
            return { filterYear: yearstring, filterMonth: monthstring };
          return {
            ...oldValue,
            filterYear: yearstring,
            filterMonth: monthstring,
          };
        });
      },
    };

    flatpickrInstance = flatpickr(inputRef.current, options); // Initialize Flatpickr

    return () => {
      flatpickrInstance.destroy(); // Destroy the Flatpickr instance to prevent memory leaks
    };
  }, [setMonthFilter]);

  return (
    <div className="">
      <Label className="form-label">Select Month</Label>
      <input
        className="form-control"
        placeholder="Select Month"
        defaultValue={monthFilter ? `${monthFilter.filterMonth}-${monthFilter.filterYear}` : ""}
        ref={inputRef}
      />
    </div>
  );
}

export default MonthPicker;