import React from "react";
import GridTables from "../Tables/GridTables/GridTables";
// import Filters from "./TableFilters";";
// import "./global.css";
const DashboardEcommerce = () => {
  return (
    <React.Fragment>
      <GridTables />
    </React.Fragment>
  );
};

export default DashboardEcommerce;
