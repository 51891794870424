import "./server.css";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import * as XLSX from 'xlsx';
import {
  Button,
  Col,
  Input,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useApi } from "../../context/ApiContext";
var row_ids = [];

export default function ReportTable({
  rowdata,
  setRows,
  jurisdictions,
  loading,
}) {
  const Api = useApi()
  var row_data_juridictions = [];
  const [show, changeShow] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [modal_togFirst, setmodal_togFirst] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [updatedcredsstatus, setUpdatedCredsStatus] = useState(false);
  const [serverStatus, setServerStatus] = useState({});
  const [activeRows, setActiveRows] = useState([]);
  const [modal, setModal] = useState(false);
  const { userIsAdmin, availableJurisdictions } = useAuth();
  const [showPasswordKeys, setShowPassword] = useState({});
  const handleServerStatusCheck = () => {
    Api
      .getServerStatus()
      .then((data) => {
        setServerStatus(data.data)
      })
      .catch((error) => {
        setServerStatus([])
      });
  };
  useEffect(()=>{

  },[serverStatus])
  row_data_juridictions = rowdata.map((row) => row.jurisdiction).flat();
  const UpdateButtonStatus = (e) => {
    let checkbox_status = e.target.checked;
    let checkbox_id = e.target.id;
    if (checkbox_status === true) {
      const updatedRows = [...activeRows, parseInt(checkbox_id)];
      row_ids.push(parseInt(checkbox_id));
      changeShow(true);
      setActiveRows(updatedRows);
    } else {
      const updatedRows = activeRows.filter((id) => id !== checkbox_id);
      row_ids.pop(parseInt(checkbox_id));
      setActiveRows(updatedRows);
    }
    if (row_ids.length === 0) {
      setActiveRows([]);
      changeShow(false);
    }
  };
  useEffect(() => {}, [activeRows]);

  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

  const addServer = () => {
    let servernameinput = document.getElementById("servernameinput").value;
    let usernameinput = document.getElementById("usernameinput").value;
    let passwordinput = document.getElementById("passwordinput").value;
    let ipaddrinput = document.getElementById("ipaddrinput").value;
    let osinput = document.getElementById("osinput").value;
    let ownerinput = document.getElementById("ownerinput").value;
    let new_server = {
      servernameinput: servernameinput,
      usernameinput: usernameinput,
      passwordinput: passwordinput,
      ipaddrinput: ipaddrinput,
      osinput: osinput,
      ownerinput: ownerinput,
    };
    let data = { request_for: "add_server", server_details: new_server };
    Api.serverCrudOps(data).then((data) => {
      if (data.rows) {
        setActiveRows([]);
        let rows = data.rows;
        setRows(rows);
        setmodal_togFirst(false);
      } else {
        toggleErrorModal();
        setmodal_togFirst(false);
        setUpdatedCredsStatus(false);
      }
    });
  };

  const handleCellClick = (e) => {
    const newData = [...rowdata];
    const field = e.target.parentElement.dataset.col;
    const id = e.target.parentElement.dataset.row;
    const value = e.target.value;
    newData[id][field] = value;
    setRows(newData);
  };

  const handleBlur = (e) => {
    const field = e.target.parentElement.dataset.col;
    const id = e.target.parentElement.dataset.id;
    const value = e.target.value;
    let data = {
      request_for: "update_server",
      server_update_details: [field, id, value],
    };
    Api.serverCrudOps(data).then((data) => {
      if (data.rows) {
        let rows = data.rows;
        setRows(rows);
        setmodal_togFirst(false);
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
        if (row_ids.includes(id)) {
          document.getElementById(id).checked = true;
        }
      } else {
        toggleErrorModal();
        setUpdatedCredsStatus(false);
      }
    });
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = async () => {
    // setIsDeleting(true);
    // setIsDeleting(false);
    let data = { request_for: "delete_rows", row_ids: row_ids };
    Api.serverCrudOps(data).then((data) => {
      let rows = data.rows;
      setRows(rows);
      toggleModal();
    });
  };

  const handleRemovejurisdiction = (event, row, col, value) => {
    event.preventDefault();
    const updatedRowData = [...rowdata];
    updatedRowData[row][col] =
      updatedRowData[row][col] &&
      updatedRowData[row][col].filter((item) => item !== value);
    // setRows(updatedRowData)
    const row_id =
      event.target.parentElement.parentElement.parentElement.dataset.id;
    const column = col;
    let cell_value = updatedRowData[row][col];
    let data = {
      request_for: "update_jurisdiction",
      params: [column, row_id, cell_value],
    };
    Api.serverCrudOps(data).then((data) => {
      let rows = data.rows;
      setRows(rows);
    });
  };
  const handleExport = () => {
    const formattedData = rowdata.map(({ ip_address, os, jurisdiction, owner }) => ({
      ip: ip_address,
      owner,
      os,
      jurisdictions: Array.isArray(jurisdiction) ? jurisdiction.join(', ') : jurisdiction,
    }));    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Server Details');
    XLSX.writeFile(wb, 'server_details.xlsx');
  };

  const handleDrop = (event, row, id, col) => {
    event.preventDefault();
    const value = event.dataTransfer.getData("text");
    const updatedRowData = [...rowdata];
    const row_id = id;
    const column = col;
    if (updatedRowData[row][col] && !updatedRowData[row][col].includes(value)) {
      updatedRowData[row][col] &&
        !updatedRowData[row][col].includes(value) &&
        updatedRowData[row][col].push(value);
      updatedRowData[row][col] = updatedRowData[row][col]
        ? updatedRowData[row][col]
        : value;
      let cell_value = updatedRowData[row][col];
      let data = {
        request_for: "update_jurisdiction",
        params: [column, row_id, cell_value],
      };
      Api.serverCrudOps(data).then((data) => {
        let rows = data.rows;
        setRows(rows);
      });
    }
  };

  const toggle = () => setModal(!modal);
  const togglePasswordVisiblity = (index) => {
    setShowPassword({
      ...showPasswordKeys,
      [index]: !showPasswordKeys[index],
    });
  };
  return (
    <>
      <div className="d-flex flex-row-reverse justify-content-between align-items-center pt-4 pb-2 gap-4">
        <div className="d-flex gap-2 d-flex-align-items-center">
        
          <button className=" btn btn-sm btn-primary " onClick={handleServerStatusCheck}>
            <div
              className="steps-btn-txt d-flex align-items-center"
              style={{ gap: "2px" }}
            > Check server status</div>
          </button>
          <button className=" btn btn-sm btn-primary " onClick={handleExport}>
            <div
              className="steps-btn-txt d-flex align-items-center"
              style={{ gap: "2px" }}
            >Export</div>
          </button>
          {/* <button className=" btn btn-sm btn-primary " onClick={toggle}>
            <div
              className="steps-btn-txt d-flex align-items-center"
              style={{ gap: "2px" }}
            >
              {" "}
              <i className="ri ri-windows-line"></i> Setup Steps
            </div>
          </button> */}
         
          {userIsAdmin ? (
            <button
              style={{ display: show ? "flex" : "none" }}
              className="delete-btn btn btn-sm btn-danger btn-label"
              onClick={toggleModal}
            >
              <i
                className={` btn-danger bx bx-trash label-icon align-middle`}
              ></i>
              <div className="delete-btn-txt">Delete</div>
            </button>
          ) : (
            ""
          )}
        </div>

        {userIsAdmin ? (
          <button
            className="add-btn btn btn-sm btn-success btn-label"
            onClick={() => setmodal_togFirst(!modal_togFirst)}
          >
            <i
              className={` btn-success bx bx-plus label-icon align-middle`}
            ></i>
            <div className="delete-btn-txt">Add Server</div>
          </button>
        ) : (
          ""
        )}
        <Modal
          modalTransition={{ timeout: 0 }}
          isOpen={modal_togFirst}
          toggle={() => {
            setmodal_togFirst(!modal_togFirst);
          }}
          id="firstmodal"
          centered
        >
          <ModalHeader className="p-5" style={{ display: "flex" }}>
            <Col style={{ display: "flex" }}>
              <h5
                style={{ width: "96%", display: "flex" }}
                className="modal-title"
                id="exampleModalToggleLabel"
              >
                ADD NEW SERVER
              </h5>
              <Button
                style={{ paddingRight: 0 }}
                type="button"
                className="btn-close"
                onClick={() => {
                  setmodal_togFirst(false);
                }}
                aria-label="Close"
              ></Button>
            </Col>
          </ModalHeader>
          <ModalBody className="p-5">
            <div className="row g-3">
              {/* <Col lg={12}>
                <div>
                  <label htmlFor="jurisdictioninput" className="form-label">
                    jurisdiction
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="jurisdictioninput"
                    placeholder="Name of Server"
                  />
                </div>
              </Col> */}
              <Col lg={12}>
                <div>
                  <label htmlFor="servernameinput" className="form-label">
                    Server Name
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="servernameinput"
                  />
                </div>
              </Col>
              <Col lg={12}>
                <label htmlFor="usernameinput" className="form-label">
                  Username
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="usernameinput"
                />
              </Col>
              <Col lg={12}>
                <label htmlFor="passwordinput" className="form-label">
                  Password
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="passwordinput"
                />
              </Col>
              <Col lg={12}>
                <label htmlFor="ipaddrinput" className="form-label">
                  IP Address
                </label>
                <Input type="text" className="form-control" id="ipaddrinput" />
              </Col>
              <Col lg={12}>
                <label htmlFor="osinput" className="form-label">
                  Operating System
                </label>
                <Input type="text" className="form-control" id="osinput" />
              </Col>
              <Col lg={12}>
                <label htmlFor="osinput" className="form-label">
                  Owner
                </label>
                <Input type="text" className="form-control" id="ownerinput" />
              </Col>
            </div>
          </ModalBody>
          <ModalFooter style={{ marginRight: "4%" }}>
            <div className="row g-3">
              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    className={`cart-btn btn btn-md btn-warning btn-label`}
                    onClick={() => {
                      addServer();
                    }}
                  >
                    <i className={`mdi mdi-server label-icon align-middle`}></i>
                    <div className="cart-btn-txt">Add</div>
                  </button>
                </div>
              </Col>
            </div>
          </ModalFooter>
        </Modal>
        <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
          <div className="modal-content border-0">
            <ModalBody className="p-0  status-popup">
              <div
                className={
                  updatedcredsstatus
                    ? "alert alert-success alert-dismissible  show m-0"
                    : "alert alert-danger alert-dismissible  show m-0"
                }
                role="alert"
              >
                {updatedcredsstatus ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: "Details has been Added",
                    }}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{ __html: "Something Went Wrong" }}
                  />
                )}
                <button
                  type="button"
                  className="btn-close alrt-clse"
                  onClick={toggleErrorModal}
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
          </div>
        </Modal>
        <Modal isOpen={isOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>CONFIRM DELETE</ModalHeader>
          <ModalBody>Are you sure you want to delete this item?</ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              disabled={isDeleting}
              onClick={toggleModal}
            >
              Cancel
            </Button>{" "}
            <Button color="danger" disabled={isDeleting} onClick={handleDelete}>
              {isDeleting ? "Deleting..." : "Delete"}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            STEPS TO INSTALL OPENSSH ON A WINDOWS SERVER IN LIGHTSAIL
          </ModalHeader>
          <ModalBody>
            <ol>
              <li>Create a new Lightsail Windows Server</li>
              <li>Login through RDP</li>
              <li>Open Add/Remove Programs</li>
              <li>Click Optional Features</li>
              <li>
                Add new feature --&gt; search for OpenSSH Server --&gt; Install
              </li>
              <li>Open PowerShell and run this command: Start-Service sshd</li>
              <li>Open Windows Search and open Services</li>
              <li>
                In Services, find "OpenSSH SSH Server" and change startup type
                from Manual to Automatic
              </li>
              <li>Restart server</li>
            </ol>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div
        className="card"
        id="drag-source"
        style={{
          display: jurisdictions.includes(row_data_juridictions)
            ? "none"
            : "flex",
        }}
      >
        {/* <div
          className="px-2 mt-2"
        >
          <label>Jurisdictions </label>  
          <span><i> (drag and drop into <b>Jurisdiction</b> column)</i>
          </span>
        </div> */}
        <div>
          {jurisdictions &&
            jurisdictions.sort().map((val, key) => {
              if (!row_data_juridictions.includes(val)) {
                return (
                  <span
                    draggable={activeRows.length > 0 ? "true" : "false"}
                    key={key}
                    onDragStart={(e) => {
                      e.dataTransfer.setData(
                        "text/plain",
                        e.target.textContent
                      );
                    }}
                    style={{ fontSize: 12, cursor: "pointer" }}
                    className={"badge badge-soft-danger p-2 m-2"}
                  >
                    {val}
                  </span>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
      <div id="report-table-container">
        <div className="table-responsive" style={{maxHeight:400,overflowY:'auto'}} >
          <table
            id="report-table"
            className="table table-nowrap my-0 py-0 all-users"
          >
            <thead>
              {rowdata.length > 0 ? (
                <tr>
                  <th></th>
                  <th>IP Address</th>
                  <th>Username</th>
                  <th>Password</th>
                  <th>Service</th>
                  <th>Owner</th>
                  <th>Operating System</th>
                  <th>Jurisdiction</th>
                </tr>
              ) : (
                <tr>
                  <th></th>
                </tr>
              )}
            </thead>
            {rowdata.length > 0 ? (
              <tbody>
                {rowdata.map((arr, key) => (
                  <tr style={{ textAlign: "center" }} key={key}>
                    <td>
                      <div
                        className="form-check-success"
                        style={{ textAlign: "center" }}
                      >
                        {userIsAdmin ? (
                          <Input
                            onChange={UpdateButtonStatus}
                            className="form-check-input"
                            type="checkbox"
                            style={{ border: "1px solid #bbbbbb" }}
                            id={arr.id}
                          />
                        ) : (
                          key + 1
                        )}
                      </div>
                    </td>
                    <td data-col="ip_address" data-row={key} data-id={arr.id}>
                      {activeRows.includes(arr.id) ? (
                        <Input
                          type="text"
                          onChange={(e) => handleCellClick(e)}
                          value={arr.ip_address ? arr.ip_address : ""}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        ></Input>
                      ) : (
                        <div style={{display:"flex",gap:"1rem",justifyContent:"space-between"}}>
                          <span>{arr.ip_address}</span>
                          <span><i className="mdi mdi-monitor" style={{color: serverStatus[arr.ip_address] == true ? 'orange':''}} ></i></span>
                        </div>
                      )}
                    </td>
                    <td data-col="username" data-row={key} data-id={arr.id}>
                      {activeRows.includes(arr.id) ? (
                        <Input
                          disabled={!activeRows.includes(arr.id)}
                          type="text"
                          onChange={(e) => handleCellClick(e)}
                          value={arr.username ? arr.username : ""}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        ></Input>
                      ) : (
                        arr.username
                      )}
                    </td>
                    <td data-col="password" data-row={key} data-id={arr.id}>
                      {activeRows.includes(arr.id) ? (
                        <Input
                          type="text"
                          onChange={(e) => handleCellClick(e)}
                          value={arr.password ? arr.password : ""}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        ></Input>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {showPasswordKeys[key] ? (
                            <span>{arr.password}</span>
                          ) : (
                            <span style={{ marginTop: 2 }}>
                              {"*".repeat(20)}
                            </span>
                          )}
                          <button
                            onClick={() => togglePasswordVisiblity(key)}
                            className={
                              showPasswordKeys[key]
                                ? "btn mdi mdi-eye-off"
                                : "btn mdi mdi-eye"
                            }
                          ></button>
                        </div>
                      )}
                    </td>
                    <td data-col="server_name" data-row={key} data-id={arr.id}>
                      {activeRows.includes(arr.id) ? (
                        <Input
                          type="text"
                          onChange={(e) => handleCellClick(e)}
                          value={arr.server_name ? arr.server_name : ""}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        ></Input>
                      ) : (
                        arr.server_name
                      )}
                    </td>
                    <td data-col="owner" data-row={key} data-id={arr.id}>
                      {activeRows.includes(arr.id) ? (
                        <Input
                          type="text"
                          onChange={(e) => handleCellClick(e)}
                          value={arr.owner ? arr.owner : ""}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        ></Input>
                      ) : (
                        arr.owner
                      )}
                    </td>
                    <td data-col="os" data-row={key} data-id={arr.id}>
                      {activeRows.includes(arr.id) ? (
                        <Input
                          type="text"
                          onChange={(e) => handleCellClick(e)}
                          value={arr.os ? arr.os : ""}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        ></Input>
                      ) : (
                        arr.os
                      )}
                    </td>
                    <td
                      data-col="jurisdiction"
                      data-row={key}
                      data-id={arr.id}
                      onDrop={(e) => handleDrop(e, key, arr.id, "jurisdiction")}
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          minHeight: 50,
                          gap: "1rem",
                        }}
                      >
                        {arr.jurisdiction.sort().map((i, e) => {
                          return (
                            <span
                              key={e}
                              style={{
                                fontSize: 12,
                                cursor: "pointer",
                                height: 37,
                                gap:5,
                                display: "flex",
                                alignItems: "center",
                              }}
                              className={"badge badge-soft-secondary p-2 my-1 "}
                            >
                              {i}
                              {activeRows.includes(arr.id) ? (
                                <Button
                                  className="jurisdiction-badge  btn-close close-btn-users"
                                  style={{ fontWeight: "bold" ,fontSize:10}}
                                  onClick={(event) =>
                                    handleRemovejurisdiction(
                                      event,
                                      key,
                                      "jurisdiction",
                                      i
                                    )
                                  }
                                >
                                  <i className="fa fa-close"></i>
                                </Button>
                              ) : (
                                ""
                              )}
                            </span>
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                {loading ? (
                  <tr>
                    <td>
                      <div className="loader-container">
                        <div className="loader"></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td style={{ fontWeight: "400", textAlign: "center" }}>
                      No data available.
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
