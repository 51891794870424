import react from "react";
import { Card, CardBody, Button, Col, Container, Row, Label } from "reactstrap";
import GridTables from "./GridTables";
// import MetaTags from "react-meta-tags";
// import MonthPicker from "./MonthPicker";
// import DatePicker from "../../monitoring/DatePicker";
import MonthPicker from "./MonthPicker";
function Filters() {
  return (
    <>
      {/* <Col md={12}> */}
      <div style={{ display: "flex" }}>
        {/* <div style={{ width: "45%" }}>
          <DatePicker />
        </div> */}
        {/* <div
          md={1}
          style={{
            // width: "5%",
            paddingTop: "17px",
            textAlign: "center",
          }}
        >
          <Label> </Label>
          <div> or</div>
        </div> */}
        <div style={{ width: "50%" }}>
          <MonthPicker />
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "end",
            // paddingRight: 0,
          }}
        >
          <Button
            color="primary"
            className="btn-label"
            // data-text="Reset"
          >
            {" "}
            <span>
              <i className="label-icon  align-middle bx bx-reset" />
              Reset
            </span>{" "}
          </Button>
        </div> */}
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "end",
            // paddingRight: 0,
          }}
        >
          <Button style={{ width: 97 }} color="primary" className="btn-label">
            <i className="label-icon align-middle bx bx-export" />
            <span>Export</span>
          </Button>
        </div>
      </div>

      {/* </Col> */}
      <Row
        className="invisible"
        style={{
          flexDirection: "row-reverse",
          marginTop: "2.0rem",
          marginBottom: "0.5rem",
          gap: "1.5rem",
        }}
        md={12}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "end",
            paddingRight: 0,
          }}
        >
          <div>sdgsersegge</div>
        </div>
      </Row>
      <Row>
        <GridTables />
      </Row>
    </>
  );
}

export default Filters;
