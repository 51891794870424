import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import { Label } from "reactstrap";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
// import "./styles.css";
require("flatpickr/dist/plugins/monthSelect/style.css");
require("flatpickr/dist/flatpickr.css");

function MonthPicker() {
  const options = {
    plugins: [
      new monthSelectPlugin({
        dateFormat: "m-Y",
      }),
    ],
    maxDate: new Date(),
  };

  return (
    <div className="">
      <Label className="form-label ">Select Month</Label>
      <Flatpickr
        placeholder="Select Month"
        className="form-control"
        options={options}
        value={new Date()}
      />
    </div>
  );

  //   <Flatpickr options={options} value={new Date()} />
}
export default MonthPicker;
