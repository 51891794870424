import React from "react";

import MetaTags from "react-meta-tags";
import Servers from "./servers";
import { Container } from "reactstrap";
// import "./global.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
const captcha = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Servers</title>
          </MetaTags>
          <BreadCrumb title="Servers" pageTitle="Dashboards" />
          <Servers />
        </Container>
      </div>
    </>
  );
};

export default captcha;
