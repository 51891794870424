import React from "react";
import GridTables from "../Tables/GridTables/GridTables";
// import Filters from "./TableFilters";";
import MetaTags from "react-meta-tags";
import TableFilters from "./TableFilters";
import { Col, Container, Row } from "reactstrap";
import "./global.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
const Monitoring = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Monitoring Dashboard</title>
          </MetaTags>
          <BreadCrumb title="Monitoring" pageTitle="Dashboards" />
          <TableFilters />
        </Container>
      </div>
    </>
  );
};

export default Monitoring;
