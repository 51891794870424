import React, { useEffect, useState } from "react";
import { ColumnFilter } from "../../common";
import { useApi } from "../../context/ApiContext";
import { useAuth } from "../../context/AuthContext";
import {
  Progress,
  ModalHeader,
  Modal,
  ModalBody,
  Button,
  Col,
  Input,
  ModalFooter,
} from "reactstrap";
import * as XLSX from "xlsx";
import moment from "moment";
import { objectArrayDateSorterAsc } from "../../utils";
import "./tables.css";

export default function ReportTable({
  rowdata,
  columndata,
  exportdata,
  loading,
  expected_time,
  github_links,
}) {
  const initialFilterAt = {
    Date: [],
  };
  const initialSortAt = {
    Date: 1,
  };
  const Api = useApi()
  const [allTableData, setAllTableData] = useState([]);
  const [filterAt, setFilterAt] = useState(initialFilterAt);
  const [rows, setRows] = useState([]);
  const [clientRows, setClientRows] = useState([{ email: "", name: "" }]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [notesModalOpen, setNotedModalOpen] = useState(false);
  const [notes, setNotes] = useState('');
  const [github_link, setGitLink] = useState('');
  const [sortAt, setSortAt] = useState(initialSortAt);
  const [filterOrderKey, setFilterOrderKey] = useState([]);
  const [modal_togjurisdiction, setmodal_addjurisdiction] = useState(false);
  const [filtereddates, setFilteredDates] = useState([]);
  const [reportsCounts, setReportCount] = useState({});
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedJurisdiction, setSelectedJurisdiction] = useState([]);
  const WarningConditionVariablesStatus = ["Completed","No Data Found","No Files Loaded","Website Not Updated","Jurisdiction Not Active"]
  const currentTime = moment().format("HH:mm:ss");
  const { userIsAdmin, availableJurisdictions,userIsDeveloper} = useAuth();
  
  useEffect(() => {
    setRows(rowdata);
    setColumns(columndata);
    setAllTableData(rowdata);
    setFilteredTableData(rowdata);
    // setGithubLinks(github_links)
  }, [rowdata, columndata,github_links]);

  useEffect(() => {
    setClientRows([{ email: "", name: "" }]);
  }, [modal_togjurisdiction]);

  function updateSortAt(colKey) {
    setSortAt({ ...initialSortAt, [colKey]: (sortAt[colKey] + 1) % 3 });
  }
  function returnCurrentDay(date) {
    let day = moment(date, "MM-DD-YYYY").format("ddd");
    return day;
  }
  function getSortingKey() {
    for (let [key, value] of Object.entries(sortAt)) {
      if (value === 0) {
        return [key, "asc"];
      } else if (value === 1) {
        return [key, "desc"];
      }
    }

    return ["", ""];
  }

  const toggleNotesModal = () => setNotedModalOpen(!notesModalOpen);

  function sortData(data) {
    const [sortKey, order] = getSortingKey();

    if (order === "asc") {
      if (sortKey === "Date") {
        data.sort((a, b) => new Date(a[0]) - new Date(b[0]));
      }
    } else if (order === "desc") {
      if (sortKey === "Date") {
        data.sort((b, a) => new Date(a[0]) - new Date(b[0]));
      }
    }
    return data;
  }

  function filterData(data) {
    for (let [key, value] of Object.entries(filterAt)) {
      const values = value.map((e) => e.value);
      if (values.length === 0) {
        if (filterOrderKey.includes(key))
          setFilterOrderKey(filterOrderKey.filter((e) => e !== key));
        continue;
      }

      if (!filterOrderKey.includes(key))
        setFilterOrderKey([...filterOrderKey, key]);

      data = data.filter((e) => values.includes(e[0]));
    }
    setFilteredDates(data);
    return data;
  }

  useEffect(() => {
    setRows(sortData(filterData([...allTableData])));
  }, [sortAt, filterAt, allTableData]);

  function getSortClass(colKey) {
    switch (sortAt[colKey]) {
      case 0:
        return "bx-sort-a-z";
      case 1:
        return "bx-sort-z-a";
      default:
        return "bx-sort-z-a";
    }
  }
  const calculateTotalReports = (originalData) => {
    const selectedDates = filtereddates.map((item) => item[0]);
    const { transformedData, averages } = transformDataReports(
      originalData,
      selectedDates
    );
    return averages;
  };

  useEffect(() => {
    if (exportdata && filtereddates.length > 0) {
      let reports = calculateTotalReports(exportdata);
      setReportCount(reports);
    } else {
      setReportCount({}); // Set to an empty object if no filtered dates are available
    }
  }, [exportdata, filtereddates, columndata]);

  const transformDataReports = (originalData, selectedDates) => {
    const transformedData = {};

    // Extract all unique jurisdictions
    const jurisdictions = columndata;
    selectedDates.forEach((date) => {
      transformedData[date] = {};
      jurisdictions.forEach((jurisdiction) => {
        transformedData[date][jurisdiction] = ""; // Empty value
      });
    });

    // Filter the original data for selected dates
    const filteredData = originalData.filter((row) =>
      selectedDates.includes(row[1])
    );

    // Sanitize jurisdiction names by removing any extra spaces or formatting
    const sanitizedJurisdictions = jurisdictions.map((jurisdiction) =>
      jurisdiction.trim()
    );

    // Fill in the status where applicable
    filteredData.forEach((row) => {
      const jurisdiction = row[0].trim(); // Sanitize jurisdiction name
      const date = row[1];
      const status = row[2];

      transformedData[date][jurisdiction] = status;
    });

    // Calculate averages for each jurisdiction
    const averages = {};
    sanitizedJurisdictions.forEach((jurisdiction) => {
      const totalReports = filteredData.reduce((total, row) => {
        return (
          total + (row[0].trim() === jurisdiction ? parseInt(row[2]) || 0 : 0)
        );
      }, 0);

      const dateCounts = selectedDates.reduce((count, date) => {
        const all_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        if (
          
          exportdata.some((item) => {
            const itemDays = item[4];
            const status = item[3]; 
            const cur_arr_jurisdiction = item[0]; 
            const itemDate = new Date(item[1]); 
            return (
              itemDate.toDateString() === new Date(date).toDateString() &&
              itemDays && itemDays.includes(all_days[new Date(date).getDay()]) && (status !== 'No Files Loaded')  && (status !== 'Website Not Updated') && (status !== 'Jurisdiction Not Active') && ( cur_arr_jurisdiction == jurisdiction)
            );
          })
        ) {
          return count + 1;
        }
        return count;
      }, 0);
      averages[jurisdiction] = (totalReports / dateCounts).toFixed(2) || 0;
    });

    return { transformedData, averages };
  };

  const transformData = (originalData, selectedDates) => {
    const transformedData = {};

    // Extract all unique jurisdictions
    const jurisdictions = [...new Set(originalData.map((row) => row[0]))];

    selectedDates.forEach((date) => {
      transformedData[date] = {};
      jurisdictions.forEach((jurisdiction) => {
        transformedData[date][jurisdiction] = ""; // Empty value
      });
    });

    // Fill in the status where applicable
    originalData.forEach((row) => {
      const jurisdiction = row[0];
      const date = row[1];
      const status = row[2];

      if (selectedDates.includes(date) && status) {
        transformedData[date][jurisdiction] = status;
      }
    });

    // Calculate totals for each jurisdiction
    const totals = {};
    jurisdictions.forEach((jurisdiction) => {
      totals[jurisdiction] = originalData.reduce((total, row) => {
        return total + (row[0] === jurisdiction ? parseInt(row[2]) || 0 : 0);
      }, 0);
    });

    return { transformedData, totals };
  };

  const exportExcelData = () => {
    if (exportdata) {
      const selectedDates = filtereddates.map((item) => item[0]);
      const { transformedData, totals } = transformData(
        exportdata,
        selectedDates
      );

      const jurisdictions = columndata;
      const workbook = XLSX.utils.book_new();

      const worksheetData = [
        ["Date", ...jurisdictions], // Header row with jurisdiction names
        ...selectedDates.map((date) => [
          date,
          ...jurisdictions.map(
            (jurisdiction) => transformedData[date][jurisdiction]
          ),
        ]),
      ];

      const totalReportsRow = [
        "Total Reports",
        ...jurisdictions.map((jurisdiction) => {
          const totalReports = selectedDates.reduce((sum, date) => {
            return sum + parseInt(transformedData[date][jurisdiction] || 0);
          }, 0);
          return totalReports;
        }),
      ];

      const averageReportsRow = [
        "Average Reports",
        ...jurisdictions.map((jurisdiction) => {
          const totalReports = selectedDates.reduce(
            (sum, date) =>
              sum + parseInt(transformedData[date][jurisdiction] || 0),
            0
          );
          // const dateCounts = selectedDates.length;
          const dateCounts = selectedDates.reduce((count, date) => {
            const all_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            if (
              
              exportdata.some((item) => {
                const itemDays = item[4];
                const status = item[3]; 
                const cur_arr_jurisdiction = item[0]; 
                const itemDate = new Date(item[1]); 
                return (
                  itemDate.toDateString() === new Date(date).toDateString() &&
                  itemDays && itemDays.includes(all_days[new Date(date).getDay()]) && (status !== 'No Files Loaded') && (status !== 'Website Not Updated') && (status !== 'Jurisdiction Not Active')&& ( cur_arr_jurisdiction == jurisdiction)
                );
              })
            ) {
              return count + 1;
            }
            return count;
          }, 0);
          return (totalReports / dateCounts).toFixed(2);
        }),
      ];

      worksheetData.push(totalReportsRow, averageReportsRow);

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "crash_report_summary.xlsx");
    }
  };

  function getFilterOptions(colKey) {
    let options = [...allTableData];
    const fIndex = filterOrderKey.findIndex((e) => e === colKey);
    if (fIndex !== -1) {
      for (let i = 1; i <= fIndex; i++) {
        const preKey = filterOrderKey[i - 1];
        const preOptions = filterAt[preKey].map((e) => e.value);
        options = [...filteredTableData];
      }
    }
    let toptions = null;

    if (colKey === "Car Plate") {
      toptions = options.reduce((acc, curr) => {
        curr[colKey].forEach((item) => acc.add(item));
        return acc;
      }, new Set());
    } else {
      toptions = options.reduce((acc, curr) => {
        acc.add(curr[0]);
        return acc;
      }, new Set());
    }

    const result = [...toptions.values()].map((e) => ({
      value: e,
      label: e,
    }));

    return result;
  }


  const GetJurisdictionNotes = (e, jurisdiction) => {
    let data = { jurisdiction: jurisdiction };
    Api.getJurisdictionNotes(data).then((data) => {
      if('notes' in data){
        if (data.notes !== null || data.notes !== undefined){
          setNotes(data.notes)
          setGitLink(data.github_link)
          setSelectedJurisdiction(jurisdiction)
        }
        else{
          setNotes('')
          setGitLink('')
          setSelectedJurisdiction('')
        }
        toggleNotesModal()
      }

    });

  };
  const UpdateNotesorLink = (e) =>{
    let data ={'notes':notes,'jurisdiction':selectedJurisdiction,'github_link':github_link}
    Api.UpdateNotesOrGitLink(data).then((data)=>{
      if (data.status === '200'){
        toggleNotesModal()
        setNotes('')
        setGitLink('')
        setSelectedJurisdiction('')
      }
    })
  }

  return (
    <>
      <div className="d-flex flex-row-reverse justify-content-between align-items-center pt-4 pb-2 gap-4">
        <button
          className="export-btn btn btn-sm btn-primary btn-label"
          onClick={exportExcelData}
        >
          <i
            className={` btn-primary bx bx-export label-icon align-middle`}
          ></i>
          <div className="export-btn-txt">Export</div>
        </button>
      </div>
      <Modal isOpen={notesModalOpen} toggle={toggleNotesModal}>
        <ModalHeader style={{ display: "flex" }}>
          <Col style={{ display: "flex" }}>
            <h5
              style={{ width: "96%", display: "flex" }}
              className="modal-title"
              id="exampleModalToggleLabel"
            >
              NOTES
            </h5>
            <Button
              style={{ paddingRight: 0 }}
              type="button"
              className="btn-close"
              onClick={toggleNotesModal}
              aria-label="Close"
            ></Button>
          </Col>
        </ModalHeader>
        <ModalBody className="status-popup mt-2">
          {/* Text Box for Notes */}
          <div className="mb-3">
            <textarea
              className="form-control"
              id="notes"
              rows="3"
              value ={notes ? notes :''}
              onChange={(e)=>setNotes(e.target.value)}
              readOnly ={userIsAdmin?false:true}              // Add necessary state and onChange handler if needed
              ></textarea>
          </div>

          {/* Input Box for GitHub Link */}
          <div className="mb-3">
            <label htmlFor="githubLink" className="form-label">
              <i className="mdi mdi-github"></i> GitHub Link
            </label>
            <input
              type="text"
              className="form-control"
              id="githubLink"
              value={github_link ? github_link : ''}
              onChange={(e)=>setGitLink(e.target.value)}
              readOnly ={userIsAdmin || userIsDeveloper ? false : true}              // Add necessary state and onChange handler if needed
              // Add necessary state and onChange handler if needed
            />
          </div>
        </ModalBody>
        <ModalFooter >
          <div className="row g-3">
            <Col lg={12} className="m-0 p-0">
              <div className="hstack gap-2 justify-content-end">
                {userIsAdmin || userIsDeveloper ?(<button
                  className={`cart-btn btn btn-md btn-warning btn-label`}
                  onClick={() => {
                    UpdateNotesorLink();
                  }}
                >
                  <i className={`mdi mdi-server label-icon align-middle`}></i>
                  <div className="cart-btn-txt">Update</div>
                </button>):''}
                
              </div>
            </Col>
          </div>
        </ModalFooter>
      </Modal>
      <div id="report-table-container" className="monitoring-page-table-div">
        <div
          className="table-responsive"
          style={{ maxHeight: 400, overflowY: "auto" }}
        >
          <table
            id="report-table"
            className="table  table-nowrap my-0 py-0 all-users"
          >
            <thead id="thead">
              <tr>
                {columns.length > 0 && rows.length > 0 ? (
                  <th
                    style={{ maxWidth: "15%", width: "15%" }}
                    scope="col"
                    id="date-header"
                  >
                    <div>
                      <span>Date</span>
                      <span className="filters-and-sort-icons">
                        <i
                          onClick={() => updateSortAt("Date")}
                          className={`bx ${getSortClass("Date")}`}
                        ></i>
                        <ColumnFilter
                          colKey="Date"
                          optionsSorter={objectArrayDateSorterAsc("Date")}
                          options={getFilterOptions("Date")}
                          setFilterAt={setFilterAt}
                          filterAt={filterAt}
                        />
                      </span>
                    </div>
                  </th>
                ) : (
                  <th></th>
                )}
                {columns && rows.length > 0
                  ? columns.map((name, index) => {
                      return (
                        <th scope="col" key={index} id={name + "-header"}>
                          <div
                            style={{
                              display: "flex",
                              gap: "1.2rem",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                boxSizing: "border-box",
                                display: "flex",
                                flexDirection: "column",
                                gap: "0px",
                                width: "70%",
                              }}
                            >
                              <span>{name}</span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "normal",
                                  fontStyle: "italic",
                                }}
                              >
                                Average reports:{" "}
                                {reportsCounts[name] && reportsCounts[name]}
                              </span>
                            </div>
                            <div
                              style={{
                                boxSizing: "border-box",
                                display: "flex",
                                gap: "5px",
                                width: "30%",
                                justifyContent: "end",
                              }}
                            >
                              <span>
                              {github_links[name] !== null && github_links[name] !== '' ?
                                (<a target="_blank" 
                                rel="noreferrer"
                                href={github_links[name]}
                                style={{color:'black'}}>
                                  <i
                                    className="mdi mdi-github"
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </a> ): (<i
                                    className="mdi mdi-github text-muted"
                                    style={{
                                      fontSize: "18px",
                                    }}
                                  ></i>)
                              }
                              </span>
                              <span>
                                <i
                                  className="mdi  mdi-note-edit"
                                  style={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => GetJurisdictionNotes(e, name)}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </th>
                      );
                    })
                  : null}
              </tr>
            </thead>
            {rows.length > 0 ? (
              <tbody id="report-body">
                {rows.map((arr, key) => (
                  <tr style={{ textAlign: "center" }} key={key}>
                    {arr.map((v, k) => {
                      if (v !== "-") {
                        if (
                          expected_time[key] &&
                          expected_time[key][k] &&
                          expected_time[key][k]["status"] === "Error"
                        ) {
                          return (
                            <td style={{ backgroundColor: "red" }} key={k}>
                              {v}
                            </td>
                          );
                        } else if (
                          expected_time[key] &&
                          expected_time[key][k] &&
                          expected_time[key][k]["time"] !== undefined &&
                          expected_time[key][k]["time"] !== "" &&
                          !(WarningConditionVariablesStatus.includes(expected_time[key][k]['status'])) &&
                          expected_time[key][k]["weekdays"].includes(returnCurrentDay(arr[0])) &&
                          moment(expected_time[key][k]["time"],"HH:mm:ss").isBefore(moment(currentTime, "HH:mm:ss"))
                        ) {
                          return (
                            <td style={{ backgroundColor: "red" }} key={k}>
                              {v}
                            </td>
                          );
                        } else {
                          return <td key={k}>{v}</td>;
                        }
                      } else {
                        return <td key={k}></td>;
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                {loading ? (
                  <tr>
                    <td>
                      <div className="loader-container">
                        <div className="loader"></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td style={{ fontWeight: "400", textAlign: "center" }}>
                      No data available for selected month
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
