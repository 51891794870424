import react, { useEffect, useState } from "react";
import Table from "./Tables";
import { useApi } from "../../context/ApiContext";

import { Row, Col } from "reactstrap";


function Proxies() {
  const Api = useApi()
  const [rows, setRows] = useState([]);
  const [jurisdictionCounts, setJurisdictionCounts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    Api.getJurisdictionsPageData(signal)
      .then((data) => {
        if (!abortController.signal.aborted) {
        setRows(data.jurisdictions);
        setJurisdictionCounts(data.jurisdiction_counts);
        setLoading(false);
        }
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Request aborted');
        } else {
          console.error('Error:', error);
        }
      });
    return () => abortController.abort();
  }, [Api]);
  return (
    <>
      <Row>
        <Col>
          <Table
            rowdata={rows}
            setRows={setRows}
            loading={loading}
            jurisdictionCounts={jurisdictionCounts}
            setJurisdictionCounts={setJurisdictionCounts}
          />
        </Col>
      </Row>
    </>
  );
}

export default Proxies;
