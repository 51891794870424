import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { CardBody, Col, Row } from "reactstrap";
import USMapWithSVG from "./PlotMap";
const MapComponent = ({activeCategry,selectedFilters,categories,setCategories,settotalWebsiteCount,UpdateCategoryActive,siteCounts}) => {
  
  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="card">
            <div>
              {categories &&
                 categories.sort().map((val, key) => {
                    return (
                      <span
                        key={key}
                        onClick={()=>UpdateCategoryActive(val)}
                        style={{ fontSize: 12, cursor: "pointer" }}
                        className={`badge ${
                          activeCategry === val
                            ? "bg-primary"
                            : "bg-light text-body"
                        }  p-2 m-2`}
                      >
                        {val}
                      </span>
                    );
                })}
            </div>
          </div>
        </Col>
      </Row>
      <CardBody className="d-flex justify-content-center">
        <USMapWithSVG selectedFilters={selectedFilters} activeCategry={activeCategry} setCategories={setCategories} settotalWebsiteCount={settotalWebsiteCount} siteCounts={siteCounts}  />
      </CardBody>
    </React.Fragment>
  );
};

export default MapComponent;
