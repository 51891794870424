import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import MetaTags from "react-meta-tags";
import ManagePassword from "./ManagePassword";
import ManageProfile from "./ManageProfile";
import profileBg from "../../assets/images/admin-bg.jpg";
import "./settings.css";
import avatar1 from "../../assets/images/users/user-dummy-img.jpg";
import { useAuth } from "../../context/AuthContext";

export default function Index() {
  const { currentUser,uploadProfileImage,UserProfilePic } = useAuth();
  const [btnStatus, setBtnStatus] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [imageUpdated, setImageUpdated] = useState(false);
  const [name, setName] = useState(currentUser.displayName);
  useEffect(() => {
    setName(name);
  }, [name]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleChange =  () =>{
    setBtnStatus(false);
    setImageUpdated(true)
  } 
  return (
    <div className="page-content">
      <MetaTags>
        <title>Profile Settings</title>
      </MetaTags>
      <Container fluid>
        <div className="profile-foreground position-relative mx-n4 mt-n4">
          <div className="profile-wid-bg">
            <img src={profileBg} alt="" className="profile-wid-img" />
          </div>
        </div>
        <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
          <Row className="g-4">
            <div className="profile-user col-auto">
              <img
                src={UserProfilePic !=null && UserProfilePic.photoURL ? UserProfilePic.photoURL : avatar1}
               
                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                alt="user-profile"
              />
              <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                <Input
                 onChange={handleChange}
                  id="profile-img-file-input"
                  type="file"
                  className="profile-img-file-input"
                />
                <Label
                  htmlFor="profile-img-file-input"
                  className="profile-photo-edit avatar-xs"
                >
                  <span className="avatar-title rounded-circle bg-light text-body">
                    <i className="ri-camera-fill"></i>
                  </span>
                </Label>
              </div>
            </div>
            <Col style={{ display: "grid", alignItems: "center" }}>
              <h3 className="text-white">{name}</h3>
            </Col>
          </Row>
        </div>

        <Row>
          <Col lg={12}>
            <div>
              <div className="d-flex">
                <Nav
                  pills
                  className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      href="#manage-organizations"
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => toggleTab("1")}
                    >
                      <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                      <span
                        className="d-none d-md-inline-block"
                        style={{ paddingLeft: "1rem" }}
                      >
                        Manage Profile
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#user-history"
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => toggleTab("2")}
                    >
                      <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
                      <span
                        className="d-none d-md-inline-block"
                        style={{ paddingLeft: "1rem" }}
                      >
                        Change Password
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <TabContent
                id="admin-overview-tab-content"
                activeTab={activeTab}
                className="pt-4"
              >
                <TabPane tabId="1">
                  <ManageProfile name={name} setName={setName} btnStatus={btnStatus} setBtnStatus={setBtnStatus} uploadProfileImage={uploadProfileImage} imageUpdated={imageUpdated}/>
                </TabPane>
                <TabPane tabId="2">
                  <ManagePassword />
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
