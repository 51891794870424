import React, { useState, useEffect, Fragment } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Col,
  Label,
  Link,
  Input,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { useApi } from "../../context/ApiContext";
import * as XLSX from "xlsx";

const us_states = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

const WebsiteEntry = ({ unique_names,name, link, scrapable,updated_at, removeHandler }) => {
  
  if(unique_names.includes(name)) return "";
  unique_names.push(name)
  return (
    <div data-bs-toggle="tooltip" data-bs-placement="top" title={"Last update on : "+ updated_at.split(' ')[0]}>
    <span
      style={{
        fontSize: 12,
        cursor: "pointer",
        height: 37,
        gap: 5,
        display: "flex",
        alignItems: "center",
        maxWidth: "fit-content",
      }}
      className={`badge ${
        scrapable ? "badge-soft-success " : "badge-soft-danger"
      } p-2 my-1 `}
    >
      <a
        target="_blank"
        rel="noreferrer"
        style={{ color: "inherit" }}
        href={`${
          link.includes("http") || link.includes("https")
            ? link
            : "https://" + link
        }`}
      >
        {name}
      </a>
      
      <Button
        className="jurisdiction-badge  btn-close close-btn-users"
        style={{ fontWeight: "bold", fontSize: 10 }}
        onClick={(event) => removeHandler(name, link)}
      >
        <i className="fa fa-close"></i>
      </Button>
    </span>
    </div>
  );
};

const ModalComponent = ({
  selectedFilters,
  state,
  open,
  setOpen,
  setSelectedState,
  activeCategry,
  setCategories,
  settotalWebsiteCount,
}) => {
  const [websites, setWebsites] = useState([]);
  const [isScrapable, setIsScrapable] = useState(false);
  var unique_names = []
  const Api = useApi();
  useEffect(() => {
    let requestData = {
      state: state,
      request_for: "get_websites",
      category: activeCategry,
    };
    Api.GetWebsiteLinks(requestData)
      .then((responseData) => {
        if (
          responseData.sites.length > 0 &&
          Object.keys(selectedFilters).length > 0
        ) {
          const filteredSites = responseData.sites.filter((site) => {
            return Object.keys(selectedFilters).every((filterKey) => {
              if (site[filterKey] === undefined) {
                return false;
              }
              return site[filterKey] === selectedFilters[filterKey];
            });
          });
          setWebsites(filteredSites);
        } else {
          setWebsites(responseData.sites);
        }
        setOpen(!open);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [state]);

  const removeWebsite = (name, link) => {
    const updatedWebsites = websites.filter((e) => {
      const currentName = e["name"];
      const currentLink = e["link"];
      return !(currentName === name && currentLink === link);
    });
    let data = {
      request_for: "update_websites",
      state: state,
      category: activeCategry,
      websites: updatedWebsites,
    };
    Api.GetWebsiteLinks(data)
      .then((responseData) => {
        const { sites, categories, websites_count } = responseData;
        setWebsites(sites);
        setCategories(categories);
        settotalWebsiteCount(websites_count);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const toggleErrorModal = (props) => {
    setSelectedState(null);
    setOpen(!open);
    formik.resetForm();
  };

  const ModalClosed = (props) => {
    setSelectedState(null);
    setOpen(false);
    setWebsites([]);
    formik.resetForm();
  };

  const initialValues = {
    name: "",
    link: "",
    category: activeCategry == null ? "" : activeCategry,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    category: Yup.string(),
    link: Yup.string()
      .test(
        "is-url",
        "Invalid website link",
        (value) =>
          value &&
          /^(https?:\/\/)?[a-zA-Z0-9-]+\.[a-zA-Z]{1,6}([/a-zA-Z0-9-._]*)*$/i.test(
            value
          )
      )
      .required("Link is required"),
    srapable: Yup.bool().oneOf[[true, false]],
  });
  const handleSubmit = (values, { resetForm }) => {

    let now = new Date();
    let year = now.getFullYear();
    let month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed; add 1 to match the human-readable format and pad it to ensure two digits
    let day = String(now.getDate()).padStart(2, '0');
    let hours = String(now.getHours()).padStart(2, '0');
    let minutes = String(now.getMinutes()).padStart(2, '0');
    let seconds = String(now.getSeconds()).padStart(2, '0');
    let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const nameExists = websites.some(
      (obj) =>
        obj["name"] === values.name &&
        obj["link"] === values.link &&
        obj["category"] === values.category
    );
    if (!nameExists) {
      let data = {
        request_for: "update_websites",
        state: state,
        category: activeCategry,
        websites: [
          ...websites,
          {
            name: values.name,
            link: values.link,
            category: values.category,
            scrapable: isScrapable,
            updated_at: formattedDate
          },
        ],
      };
      Api.GetWebsiteLinks(data)
        .then((responseData) => {
          setWebsites(responseData.sites);
          resetForm({ values: initialValues });
          setIsScrapable(false);
          setCategories(responseData.categories);
          settotalWebsiteCount(responseData.websites_count);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      resetForm({ values: initialValues });
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
  });
  const ExportData =()=>{
    const workbook = XLSX.utils.book_new();
    const worksheetData = websites.map(dict => ({ State: state,Category:dict.category,URL:dict.link,Name:dict.name,"Is Scrapable":dict.scrapable,"Last Updated":dict.updated_at }));
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "websites_directory.xlsx");

  }
  return (
    <Fragment>
      <Modal
        onClosed={ModalClosed}
        modalTransition={{ timeout: 0 }}
        isOpen={open}
        toggle={toggleErrorModal}
        id="firstmodal"
        centered
        size="xl"
      >
        <ModalHeader className="p-3" style={{ display: "flex" }}>
          <Col style={{ display: "flex" }}>
            <h5 className="modal-title" id="exampleModalToggleLabel">
              {us_states[state]} - Available Sites
              <span>
                <h6>Total Sites: {websites.length} </h6>
              </span>
            </h5>

            <Button
              size="sm"
              type="button"
              className="btn-primary h-50 mr-3"
              style={{marginRight:10}}
              onClick={ExportData}
            >Export</Button>

            <Button
              type="button"
              className="btn-close"
              onClick={toggleErrorModal}
              aria-label="Close"
            ></Button>
          </Col>
        </ModalHeader>
        <ModalBody className="p-3">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="d-flex flex-column">
              <div className="row w-100">
                <div className="col-md-11 d-flex gap-4 justify-content-around">
                  <div className="mr-2 w-50">
                    <label htmlFor="nameInput">Category</label>
                    <Field
                      type="text"
                      id="categoryinput"
                      name="category"
                      disabled={activeCategry != null ? true : false}
                      placeholder="Enter category"
                      className="form-control"
                    />
                  </div>

                  <div className="mr-2 w-50">
                    <label htmlFor="nameInput">Name</label>
                    <Field
                      type="text"
                      id="nameInput"
                      name="name"
                      placeholder="Enter name"
                      className="form-control"
                    />
                  </div>

                  <div className="mr-2 w-50">
                    <label htmlFor="linkInput">Link</label>
                    <Field
                      type="text"
                      id="linkInput"
                      name="link"
                      placeholder="Enter link"
                      className="form-control"
                    />
                  </div>
                  <div className="mr-2 w-20">
                    <label className="form-label" htmlFor="scrapableInput">
                      Scrapable
                    </label>
                    <div className="form-check form-switch form-switch-end form-switch-md mt-2">
                      <Input
                        className="form-check-input code-switcher"
                        type="checkbox"
                        id="scrapable"
                        name="scrapableInput"
                        checked={isScrapable}
                        onChange={(e) => setIsScrapable(e.target.checked)}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-end mt-md-3 col-md-1 mt-lg-0 justify-content-end mt-3">
                  <button type="submit" className="btn btn-primary">
                    Add
                  </button>
                </div>
              </div>
              <div className="row w-100">
                <div className="col-md-12 d-flex gap-2 justify-content-around">
                  <div className="mr-2 w-50">
                    <ErrorMessage
                      name="category"
                      component="div"
                      style={{ color: "red", marginTop: "5px" }}
                    />
                  </div>
                  <div className="mr-2 w-50">
                    <ErrorMessage
                      name="name"
                      component="div"
                      style={{ color: "red", marginTop: "5px" }}
                    />
                  </div>
                  <div className="mr-2 w-50">
                    <ErrorMessage
                      name="link"
                      component="div"
                      style={{ color: "red", marginTop: "5px" }}
                    />
                  </div>
                  <div className="mr-2 w-25">
                    <ErrorMessage
                      name="srapable"
                      component="div"
                      style={{ color: "red", marginTop: "5px" }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-end mt-md-3 col-md-1 mt-lg-0 justify-content-end mt-3"></div>
              </div>
            </Form>
          </Formik>
          <div className="d-flex flex-row gap-1 mt-3 flex-wrap">
            {websites?.sort((a, b) => a.name.localeCompare(b.name)).map((e, i) => {
              return (
                <WebsiteEntry
                  unique_names= {unique_names}
                  key={i}
                  name={e.name}
                  link={e.link}
                  updated_at={e.updated_at}
                  scrapable={e.scrapable}
                  removeHandler={removeWebsite}
                />
              );
            })}
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ModalComponent;
