import react, { useEffect, useState } from "react";
import Table from "./Tables" ;
import { Row, Col } from "reactstrap";
import { useApi } from "../../context/ApiContext";

function Servers() {
  const Api = useApi()
  const [rows, setRows] = useState([]);
  const [jurisdictions, setjurisdictions] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => { Api.getServerCreds().then((data) => {
    setRows(data.rows);
    setjurisdictions(data.jurisdictions);
    setLoading(false)
  })},[Api])
  return (
    <>
      <Row>
        <Col>
          <Table rowdata={rows} setRows={setRows} jurisdictions={jurisdictions} loading={loading} />
        </Col>
      </Row>
    </>
  );
}

export default Servers;
