import react, { useEffect, useState } from "react";
import Table from "./Tables";
import { useApi } from "../../context/ApiContext";
import { Row, Col } from "reactstrap";
function Invoice() {
  const Api = useApi()
  const [jurisdictions, setJurisdictions] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    Api.getJurisdictions().then((data) => {
      setJurisdictions(data.jurisdictions);
    });
    
  }, [Api]);
  return (
    <>
      <Row>
        <Col>
          <Table
          jurisdictions = {jurisdictions}
          />
        </Col>
      </Row>
    </>
  );
}

export default Invoice;
