import react, { useEffect, useState, useRef } from "react";
import { Col, Row, Label } from "reactstrap";
import MonthPicker from "./MonthPicker";
import Table from "./Tables";
import Cell from "./CellExpand";
import moment from "moment";
import { useAuth } from "../../context/AuthContext";
import { useApi } from "../../context/ApiContext";

function titleCase(str) {
  return str
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
function Filters() {
  const Api = useApi();
  const [columns, setcolumns] = useState([]);
  const [rows, setrows] = useState([]);
  const [expectedTimeData, setExpectedTimeData] = useState([]);
  const [monthFilter, setMonthFilter] = useState(null);
  const [exportdata, setExportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { userIsAdmin, availableJurisdictions } = useAuth();
  const [allData, setAllData] = useState(null); // Updated variable name
  const [client, setClient] = useState("");
  const [githubLinks, setGithubLinks] = useState({});
  function plot_data(data, client_name) {
    if (Object.keys(data.date_func_map).length === 0) {
      setLoading(false);
      setcolumns([]);
      setrows([]);
      setGithubLinks({});
      return [[], [], [], []];
    }
    client_name = document.getElementById("select_client")
      ? document.getElementById("select_client").value
      : "";
    const columnData = data.function_names
      .filter((c) => {
        if (client_name !== "") {
          return data.client_details_map[c].some(
            (e) => e.email === client_name
          );
        } else {
          return c;
        }
      })
      .sort();
    var [currentYear, currentMonth, endDate] = moment()
      .endOf("month")
      .format("YYYY-MM-DD")
      .split("-");
    var day = moment().format("DD");
    if (monthFilter !== null && columns) {
      if (
        currentMonth !== monthFilter.filterMonth &&
        currentYear !== monthFilter.filterYear
      ) {
        currentMonth = monthFilter.filterMonth;
        currentYear = monthFilter.filterYear;
        endDate = moment([currentYear, currentMonth - 1])
          .endOf("month")
          .format("DD");
      } else {
        endDate = day;
      }
    } else {
      endDate = day;
    }
    const rowD = [];
    const expected_time_data = [];
    for (let currentDate = 1; currentDate <= parseInt(endDate); currentDate++) {
      currentDate = currentDate.toString().padStart(2, "0");
      const dateString = `${currentMonth}-${currentDate}-${currentYear}`;
      const endDateString = `${currentMonth}-${endDate}-${currentYear}`;
      const singleRowData = [dateString];
      const single_expected_time_data = [dateString];
      for (const func of columnData) {
        const key = `${dateString}_${func}`;
        const weekdays = data.week_site_data
          .filter((e) => e.jurisdiction === func)
          .map((e) => e.weekdays);
        const site_url = data.week_site_data
          .filter((e) => e.jurisdiction === func)
          .map((e) => e.site_url);
        if (dateString === endDateString) {
          let juris_status =
            data.date_func_map[key] &&
            data.date_func_map[key].status !== undefined
              ? data.date_func_map[key].status === ""
                ? "Unknown"
                : titleCase(data.date_func_map[key].status)
              : "Unknown";
          let start_time =
            data.date_func_map[key] !== undefined &&
            data.date_func_map[key].startTime != null
              ? data.date_func_map[key].startTime.split(" ")[1].split(".")[0]
              : "";
          let end_time =
            data.date_func_map[key] !== undefined &&
            data.date_func_map[key].endTime != null
              ? data.date_func_map[key].endTime.split(" ")[1].split(".")[0]
              : "";
          let website =
            data.date_func_map[key] !== undefined &&
            data.date_func_map[key].website != null
              ? data.date_func_map[key].website
              : "";
          let ip_address =
            data.date_func_map[key] !== undefined &&
            data.date_func_map[key].ip_address != null
              ? data.date_func_map[key].ip_address
              : "";
          let func_name =
            data.date_func_map[key] !== undefined &&
            data.date_func_map[key].funcName != null
              ? data.date_func_map[key].funcName
              : "";
          let juris_week_days =
            data.date_func_map[key] !== undefined &&
            data.date_func_map[key].juris_weekdays != null
              ? data.date_func_map[key].juris_weekdays
              : "";
          let reports_count =
            data.date_func_map[key] !== undefined &&
            data.date_func_map[key].no_of_reports != null
              ? data.date_func_map[key].no_of_reports
              : "";
          let monitor =
            data.date_func_map[key] !== undefined &&
            data.date_func_map[key].monitor != null &&
            Array.isArray(JSON.parse(data.date_func_map[key].monitor))
              ? JSON.parse(data.date_func_map[key].monitor).map((monitor) => {
                  return { label: monitor, value: monitor };
                })
              : [];
          let statusTime =
            data.date_func_map[key] !== undefined &&
            data.date_func_map[key].status_time != null
              ? data.date_func_map[key].status_time
              : "";
          let developerinput =
            data.date_func_map[key] !== undefined &&
            data.date_func_map[key].developer != null &&
            Array.isArray(JSON.parse(data.date_func_map[key].developer))
              ? JSON.parse(data.date_func_map[key].developer).map(
                  (developer) => {
                    return { label: developer, value: developer };
                  }
                )
              : [];
          let expectedtimeInput =
            data.date_func_map[key] !== undefined &&
            data.date_func_map[key].expected_time != null
              ? data.date_func_map[key].expected_time
              : "";
          let available_clients =
            data.date_func_map[key] !== undefined &&
            data.date_func_map[key].client_details != null &&
            data.date_func_map[key].client_details.length !== 0
              ? data.date_func_map[key].client_details
              : [{ email: "", name: "" }];
          singleRowData.push(
            <Cell
              status={juris_status}
              startTime={start_time}
              endTime={end_time}
              website={website}
              IPaddress={ip_address}
              func_name={func_name}
              monitors={data.developers_monitors.monitors}
              developers={data.developers_monitors.developers}
              date={endDateString}
              site_url={site_url ? site_url : ""}
              weekdays_arr={weekdays.length > 0 ? weekdays[0] : []}
              juris_week_days={juris_week_days}
              reports_count={reports_count}
              monitor={monitor}
              statusTime={statusTime}
              developerinput={developerinput}
              expectedtimeInput={expectedtimeInput}
              available_clients={available_clients}
            />
          );
          single_expected_time_data.push({
            time: expectedtimeInput,
            status: juris_status,
            weekdays: weekdays.length > 0 ? weekdays[0] : [],
          });
        } else {
          if (key in data.date_func_map) {
            let juris_status =
              data.date_func_map[key].status === "" ||
              data.date_func_map[key].status === undefined
                ? "Unknown"
                : titleCase(data.date_func_map[key].status);
            let start_time = data.date_func_map[key].startTime
              ? data.date_func_map[key].startTime.split(" ")[1].split(".")[0]
              : "";
            let end_time = data.date_func_map[key].endTime
              ? data.date_func_map[key].endTime.split(" ")[1].split(".")[0]
              : "";
            let website = data.date_func_map[key].website;
            let ip_address = data.date_func_map[key].ip_address;
            let func_name = data.date_func_map[key].funcName;
            let juris_date = data.date_func_map[key].date;
            let juris_week_days = data.date_func_map[key].juris_weekdays
              ? data.date_func_map[key].juris_weekdays
              : [];
            let reports_count = data.date_func_map[key].no_of_reports
              ? data.date_func_map[key].no_of_reports
              : "";
            let monitor = data.date_func_map[key].monitor &&
            Array.isArray(JSON.parse(data.date_func_map[key].monitor))
            ? JSON.parse(data.date_func_map[key].monitor).map(
                (monitor) => {
                  return { label: monitor, value: monitor };
                }
              )
            : []
            let statusTime = data.date_func_map[key].status_time
              ? data.date_func_map[key].status_time
              : "";
            let developerinput = data.date_func_map[key].developer
              ? JSON.parse(data.date_func_map[key].developer).map(
                  (developer) => {
                    return { label: developer, value: developer };
                  }
                )
              : [];
            let expectedtimeInput = data.date_func_map[key].expected_time
              ? data.date_func_map[key].expected_time
              : "";
            let available_clients =
              data.date_func_map[key].client_details &&
              data.date_func_map[key].client_details.length !== 0
                ? data.date_func_map[key].client_details
                : [{ email: "", name: "" }];
            singleRowData.push(
              <Cell
                status={juris_status}
                startTime={start_time}
                endTime={end_time}
                website={website}
                IPaddress={ip_address}
                func_name={func_name}
                date={juris_date}
                monitors={data.developers_monitors.monitors}
                developers={data.developers_monitors.developers}
                site_url={site_url ? site_url : ""}
                weekdays_arr={weekdays.length > 0 ? weekdays[0] : []}
                juris_week_days={juris_week_days}
                reports_count={reports_count}
                monitor={monitor}
                statusTime={statusTime}
                developerinput={developerinput}
                expectedtimeInput={expectedtimeInput}
                available_clients={available_clients}
              />
            );
            single_expected_time_data.push({
              time: expectedtimeInput,
              status: juris_status,
              weekdays: weekdays.length > 0 ? weekdays[0] : [],
            });
          } else {
            singleRowData.push(
              <Cell
                status={"Unknown"}
                startTime={""}
                endTime={""}
                website={""}
                IPaddress={""}
                func_name={""}
                date={dateString}
                site_url={site_url ? site_url : ""}
                weekdays_arr={weekdays.length > 0 ? weekdays[0] : []}
                juris_week_days={[]}
                reports_count={""}
                monitors={data.developers_monitors.monitors}
                developers={data.developers_monitors.developers}
                monitor={""}
                statusTime={""}
                developerinput={[]}
                expectedtimeInput={""}
                available_clients={[{ email: "", name: "" }]}
              />
            );
            single_expected_time_data.push({
              time: "",
              status: "",
              weekdays: weekdays.length > 0 ? weekdays[0] : [],
            });
          }
        }
      }
      rowD.push(singleRowData);
      expected_time_data.push(single_expected_time_data);
      rowD.sort().reverse();
      expected_time_data.sort().reverse();
    }
    var export_data = [];
    for (let currentDate = 1; currentDate <= parseInt(endDate); currentDate++) {
      currentDate = currentDate.toString().padStart(2, "0");
      const dateString = `${currentMonth}-${currentDate}-${currentYear}`;
      const endDateString = `${currentMonth}-${endDate}-${currentYear}`;
      for (let func of data.function_names) {
        let key = dateString + "_" + func;
        if (key in data.date_func_map) {
          export_data.push([
            func,
            dateString,
            data.date_func_map[key]["no_of_reports"],
            data.date_func_map[key]["status"],
            data.date_func_map[key]["juris_weekdays"],
          ]);
        } else {
          export_data.push([func, dateString, ""]);
        }
      }
    }
    // setAllData(data)
    data.github_links ? setGithubLinks(data.github_links) : setGithubLinks({});
    columnData ? setcolumns(columnData) : setcolumns(null);
    return [export_data, rowD, expected_time_data, columnData];
  }
  useEffect(() => {
    let interval;
    let apiCompleted = true;
    const fetchData = () => {
      if (apiCompleted) {
        apiCompleted = false;
        var jurisdiction_access = {
          admin: userIsAdmin,
          jurisdictions: availableJurisdictions,
        };
        const dataToSend =
          monthFilter !== null
            ? { ...monthFilter, ...jurisdiction_access }
            : jurisdiction_access;
        Api.GetTableData(dataToSend).then((data) => {
          apiCompleted = true;
          if (data && data.date_func_map) {
            var export_data, rowD, expected_time_data, columnData;
            [export_data, rowD, expected_time_data, columnData] = plot_data(
              data,
              ""
            );
            apiCompleted = true;
            setAllData(data);
            setExpectedTimeData(expected_time_data);
            setExportData(export_data);
            setrows(rowD);
          } else {
            setAllData(null); // Reset allData to null or an appropriate initial value
            setcolumns([]);
            setrows([]);
            setLoading(false);
            apiCompleted = true;
          }
        });
      }
    };
    fetchData();
    interval = setInterval(fetchData, 60000);

    return () => {
      clearInterval(interval);
    };
    // }, 15000)
    //   return () => clearInterval(interval);
  }, [monthFilter]);

  useEffect(() => {}, [allData, client, columns]);

  function updateTable(e) {
    setClient(e.target.value);
    if (allData !== null) {
      var export_data, rowD, expected_time_data, columnData;
      [export_data, rowD, expected_time_data, columnData] = plot_data(
        allData,
        e.target.value
      );
      setExpectedTimeData(expected_time_data);
      setExportData(export_data);
      setrows(rowD);
    }
  }
  return (
    <>
      <Row style={{ display: "flex" }}>
        <Col style={{ maxWidth: "21%" }}>
          <MonthPicker
            setMonthFilter={setMonthFilter}
            monthFilter={monthFilter}
          />
        </Col>
        <Col
          style={{
            maxWidth: "30%",
          }}
        >
          {/* {allData && allData.client_details.length > 0 ? ( */}
          <>
            <Label className="form-label">
              Select Client (Jurisdictions : {columns.length})
            </Label>
            <select
              value={
                allData &&
                allData.client_details.some((e) => e.email === client)
                  ? client
                  : ""
              }
              className="form-select"
              onChange={(e) => updateTable(e)}
              id="select_client"
            >
              <option value="">All</option>
              {allData &&
                allData.client_details.length > 0 &&
                allData.client_details
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((e, i) => (
                    <option value={e.email} key={i}>
                      {e.name}
                    </option>
                  ))}
            </select>
          </>
          {/* ) : (
            ""
          )} */}
        </Col>
      </Row>
      <Table
        rowdata={rows}
        columndata={columns}
        exportdata={exportdata}
        loading={loading}
        setExportData={setExportData}
        expected_time={expectedTimeData}
        github_links={githubLinks}
        setGithubLinks={setGithubLinks}
      />
    </>
  );
}

export default Filters;
