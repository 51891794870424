import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  ModalBody,
  Form,
  Modal,
  Button,
  FormFeedback,
} from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
// import {  apiError } from "../../store/actions";

//redux
import { useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { useApi } from "../../context/ApiContext";

const AddUser = () => {
  const Api = useApi();
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);
  const [jurisdictions, setjurisdictions] = useState([]);
  const [jurisdictionList, setJurisdictionList] = useState([]);

  useEffect(() => {
    Api.getJurisdictions().then((data) => {
      setjurisdictions(data.jurisdictions);
    });
  }, []);
  useEffect(() => {}, [jurisdictionList]);
  const handleSignUp = async (values) => {
    try {
      if (values.role === "") {
        return;
      }
      let values_to_send = {
        email: values.email,
        password: values.password,
        role: values.role,
        name: values.name,
        jurisdictions: jurisdictionList,
      };
      const data = await Api.AddUser(values_to_send);
      if (data.status === "success") {
        history.push("/monitoring");
      } else {
        if (data.error.includes("email already exists")) {
          setErrorMsg(`Email already exists.`);
        } else if (
          data.error.includes(
            "Invalid password string. Password must be a string at least 6 characters long."
          )
        ) {
          console.log("in");
          setErrorMsg(`Password should be 6 characters long.`);
        }
        toggleErrorModal();
      }
    } catch (error) {
      console.error("Error signing up", error);
    }
  };
  const handleRemovejurisdiction = (event, value) => {
    event.preventDefault();
    let jurisdictionArray = [...jurisdictions, value];
    jurisdictionArray.sort();
    setjurisdictions(jurisdictionArray);

    const updatedItems = jurisdictionList.filter((item) => item !== value);
    setJurisdictionList(updatedItems);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const value = event.dataTransfer.getData("text");

    setJurisdictionList([...jurisdictionList, value]);

    const updatedItems = jurisdictions.filter((item) => item !== value).sort();
    setjurisdictions(updatedItems);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
      role: "",
      name: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(" "),
      password: Yup.string().required(" "),
      role: Yup.string().required(" "),
      name: Yup.string().required(" "),
    }),
    onSubmit: handleSignUp,
  });

  const { user, registrationError } = useSelector((state) => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
  }));

  return (
    <React.Fragment>
      {/* <ParticlesAuth> */}
      <div className="page-content">
        <MetaTags>
          <title>Add User</title>
        </MetaTags>
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Add new user</h5>
                  </div>
                  <div className="p-2 mt-4">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      className="needs-validation"
                      action="#"
                    >
                      {user && user ? (
                        <Alert color="success">Add User Successfully</Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">
                          <div>{registrationError}</div>
                        </Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label htmlFor="useremail" className="form-label">
                          Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Enter name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.name}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="useremail" className="form-label">
                          Email <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email address"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label htmlFor="userpassword" className="form-label">
                          Password <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.password}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="role" className="form-label">
                          Role <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="role"
                          type="select"
                          placeholder="Enter role"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.role || ""}
                          invalid={
                            validation.touched.role && validation.errors.role
                              ? true
                              : false
                          }
                        >
                          <option value="">Select Role</option>
                          <option value="admin" name="admin">
                            Admin
                          </option>
                          <option value="developer" name="developer">
                            Developer
                          </option>
                          <option value="user" name="user">
                            Monitor
                          </option>
                        </Input>
                        {validation.touched.role && validation.errors.role ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.role}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="role" className="form-label">
                          Jurisdictions <span className="text-danger">*</span>
                        </Label>

                        <div className="card" id="drag-source">
                          <div>
                            {jurisdictions &&
                              jurisdictions.map((val, key) => {
                                return (
                                  <span
                                    draggable={true}
                                    key={key}
                                    onDragStart={(e) => {
                                      e.dataTransfer.setData(
                                        "text/plain",
                                        e.target.textContent
                                      );
                                    }}
                                    style={{ fontSize: 12, cursor: "pointer" }}
                                    className={
                                      "badge badge-soft-danger p-2 m-2"
                                    }
                                  >
                                    {val}
                                  </span>
                                );
                              })}
                          </div>
                        </div>
                        <div className="mt-4 ">
                          <div
                            type="card"
                            className="text-wrap"
                            style={{
                              minHeight: "45px",
                              border: "1px solid #ced4da",
                              paddingInline: "10px",
                              display: "flex",
                              flexWrap:'wrap',
                              gap: "10px",
                              alignItems: "center",
                            }}
                            onDragOver={(e) => {
                              e.preventDefault();
                            }}
                            onDrop={(e) => handleDrop(e)}
                            readOnly={true}
                          >
                            {jurisdictionList.map((i, e) => {
                              return (
                                <span
                                  key={e}
                                  style={{
                                    fontSize: 12,
                                    cursor: "pointer",
                                    height: 30,
                                    gap:5,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className={
                                    "badge badge-soft-secondary p-2 my-1 "
                                  }
                                >
                                  {i}
                                  <Button
                                  className="jurisdiction-badge  btn-close close-btn-users"
                                  style={{ fontWeight: "bold" ,fontSize:10}}
                                    onClick={(event) =>
                                      handleRemovejurisdiction(event, i)
                                    }
                                  >
                                    <i className="fa fa-close"></i>
                                  </Button>
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Add User
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
            <div className="modal-content border-0">
              <ModalBody className="p-0 error-modal-body">
                <div
                  className="alert alert-danger alert-dismissible fade show m-0"
                  role="alert"
                >
                  <div dangerouslySetInnerHTML={{ __html: errorMsg }} />
                  <button
                    type="button"
                    className="btn-close  modal-error-btn"
                    onClick={toggleErrorModal}
                    aria-label="Close"
                  ></button>
                </div>
              </ModalBody>
            </div>
          </Modal>
        </Container>
      </div>
      {/* </ParticlesAuth> */}
    </React.Fragment>
  );
};

export default AddUser;
