import React from "react";
import MetaTags from "react-meta-tags";
import Invoice from "./invoice";
import { Col, Container, Row } from "reactstrap";
import "./invoice.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
const Invoices = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Invoice</title>
          </MetaTags>
          <BreadCrumb title="Invoice" pageTitle="Dashboards" />
          <Invoice />
        </Container>
      </div>
    </>
  );
};

export default Invoices;
