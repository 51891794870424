import { React, useState, useEffect } from "react";
import {
  Progress,
  ModalHeader,
  Modal,
  ModalBody,
  Button,
  Col,
  Input,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import { useApi } from "../../context/ApiContext";
const ModalComponent = ({ open, isOpen, setRows, setJurisdictionCounts }) => {
  const [clientRows, setClientRows] = useState([{ email: "", name: "" }]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [developers, setDevelopers] = useState([]);
  const [monitors, setMonitors] = useState([]);
  const [selectedDevelopers, setSelectedDevelopers] = useState([]);
  const [selectedMonitors, setSelectedMonitors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingInsert, setLoadingInsert] = useState(false);
  const Api = useApi();
  useEffect(() => {
    setLoading(true);
    Api.GetMonitorsAndDevelopers()
      .then((data) => {
        setDevelopers(data.developers);
        setMonitors(data.monitors);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleDayClick = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const addRow = () => {
    const currentRow = clientRows[clientRows.length - 1];
    if (currentRow.email === "" || currentRow.name === "") {
      alert("Please fill out the current row before adding a new one.");
      return;
    }

    const newRow = { email: "", name: "" };
    setClientRows([...clientRows, newRow]);
  };

  const addjurisdiction = () => {
    setLoadingInsert(true);
    let jurisdiction = document.getElementById("jurisdictioninput").value;
    let site_url = document.getElementById("siteurlinput").value;
    let avg_runtime = document.getElementById("completiontime").value;
    if (jurisdiction === "") {
      return;
    }
    const developers = selectedDevelopers.map((element) => {
      return element.value;
    });
    const monitors = selectedMonitors.map((element) => {
      return element.value;
    });
    let weekdays = selectedDays;
    const nonEmptyClientRows = clientRows.filter(
      (row) => row.email !== "" || row.name !== ""
    );
    let data = {
      request_for: "add_jurisdiction",
      new_jurisdiction: jurisdiction,
      site_url: site_url,
      weekdays: weekdays,
      developer: developers,
      monitor: monitors,
      avg_runtime: avg_runtime,
      clients: nonEmptyClientRows.length > 0 ? nonEmptyClientRows : [],
    };

    Api.UpdateServerDetails(data).then((data) => {
      setSelectedDays([]);
      setLoadingInsert(false);
      isOpen(!open);
      setRows(data.jurisdictions);
      setJurisdictionCounts(data.jurisdiction_counts);
    });
  };
  const handleInputChange = (e, index, field) => {
    const updatedRows = [...clientRows];
    updatedRows[index][field] = e.target.value;
    setClientRows(updatedRows);
    if (
      updatedRows[index].email === "" &&
      updatedRows[index].name === "" &&
      index !== updatedRows.length - 1
    ) {
      updatedRows.splice(index, 1);
      setClientRows(updatedRows);
    }
  };
  function handleDeveloperSelection(values) {
    setSelectedDevelopers(values);
  }
  function handleMonitorSelection(values) {
    setSelectedMonitors(values);
  }
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
  };
  return (
    <Modal
      modalTransition={{ timeout: 0 }}
      isOpen={!loading && open}
      toggle={() => {
        isOpen(!open);
      }}
      id="firstmodal"
      centered
    >
      <ModalHeader className="p-5" style={{ display: "flex" }}>
        <Col style={{ display: "flex" }}>
          <h5
            style={{ width: "96%", display: "flex" }}
            className="modal-title"
            id="exampleModalToggleLabel"
          >
            ADD NEW JURISDICTION
          </h5>
          <Button
            style={{ paddingRight: 0 }}
            type="button"
            className="btn-close"
            onClick={() => {
              isOpen(!open);
            }}
            aria-label="Close"
          ></Button>
        </Col>
      </ModalHeader>
      <ModalBody className="p-5">
        <div className="row g-3">
          <Col lg={12}>
            <div>
              <label htmlFor="jurisdictioninput" className="form-label">
                Jurisdiction
              </label>
              <Input
                type="text"
                className="form-control"
                id="jurisdictioninput"
              />
            </div>
          </Col>
          <Col lg={12}>
            <div>
              <label htmlFor="siteurlinput" className="form-label">
                Site URL
              </label>
              <Input type="text" className="form-control" id="siteurlinput" />
            </div>
          </Col>
          <Col lg={12}>
            <label htmlFor="siteurlinput" className="form-label">
              Developer
            </label>
            <div>
              <Select
                styles={selectStyles}
                id="developerselect"
                value={selectedDevelopers}
                isMulti={true}
                isClearable={true}
                onChange={(e) => {
                  handleDeveloperSelection(e);
                }}
                options={developers}
              />
            </div>
          </Col>
          <Col lg={12}>
            <label htmlFor="siteurlinput" className="form-label">
              Monitor
            </label>
            <div>
              <Select
                style={{ zIndex: 1 }}
                value={selectedMonitors}
                isMulti={true}
                isClearable={true}
                onChange={(e) => {
                  handleMonitorSelection(e);
                }}
                options={monitors}
              />
            </div>
          </Col>
          <Col lg={12}>
            <label htmlFor="siteurlinput" className="form-label">
              Client Details
            </label>
            <div className="d-flex align-items-center justify-content-end">
              <button onClick={addRow} className="btn btn-primary">
                Add Row
              </button>
            </div>
            <div
              className="table-responsive mt-2"
              style={{ maxHeight: 400, overflowY: "auto" }}
            >
              <table
                id="report-table"
                className="table  table-nowrap my-0 py-0 all-users"
              >
                <thead>
                  <tr>
                    <th style={{ height: "1rem" }}>Email</th>
                    <th style={{ height: "1rem" }}>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {clientRows.sort().map((row, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="email"
                          value={row.email}
                          onChange={(e) => handleInputChange(e, index, "email")}
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={row.name}
                          onChange={(e) => handleInputChange(e, index, "name")}
                          className="form-control"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
          <Col lg={12}>
            <div>
              <label htmlFor="siteurlinput" className="form-label">
                Expected Time For Completion
              </label>
              <Input type="time" className="form-control" id="completiontime" />
            </div>
          </Col>
          <Col lg={12}>
            <label htmlFor="siteurlinput" className="form-label">
              Weekdays
            </label>
            <div style={{ userSelect: "none" }}>
              <div
                className={`weekday-pill ${
                  selectedDays.includes("Mon") && "selected"
                }`}
                onClick={() => handleDayClick("Mon")}
              >
                Mon
              </div>
              <div
                className={`weekday-pill ${
                  selectedDays.includes("Tue") && "selected"
                }`}
                onClick={() => handleDayClick("Tue")}
              >
                Tue
              </div>
              <div
                className={`weekday-pill ${
                  selectedDays.includes("Wed") && "selected"
                }`}
                onClick={() => handleDayClick("Wed")}
              >
                Wed
              </div>
              <div
                className={`weekday-pill ${
                  selectedDays.includes("Thu") && "selected"
                }`}
                onClick={() => handleDayClick("Thu")}
              >
                Thu
              </div>
              <div
                className={`weekday-pill ${
                  selectedDays.includes("Fri") && "selected"
                }`}
                onClick={() => handleDayClick("Fri")}
              >
                Fri
              </div>
              <div
                className={`weekday-pill ${
                  selectedDays.includes("Sat") && "selected"
                }`}
                onClick={() => handleDayClick("Sat")}
              >
                Sat
              </div>
              <div
                className={`weekday-pill ${
                  selectedDays.includes("Sun") && "selected"
                }`}
                onClick={() => handleDayClick("Sun")}
              >
                Sun
              </div>
            </div>
          </Col>
        </div>
      </ModalBody>
      <ModalFooter style={{ marginRight: "4%" }}>
        <div className="row g-3">
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <button
                className={`cart-btn btn btn-md btn-warning btn-label`}
                onClick={() => {
                  addjurisdiction();
                }}
              >
                <i
                  className={`mdi ${
                    loadingInsert ? "mdi-spin mdi-loading" : "mdi-server"
                  } label-icon align-middle`}
                ></i>
                <div className="cart-btn-txt">Add</div>
              </button>
            </div>
          </Col>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ModalComponent;
