import "./proxies.css";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  Button,
  Col,
  Input,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useApi } from "../../context/ApiContext";
var row_ids = [];
export default function ReportTable({
  rowdata,
  setRows,
  jurisdictions,
  loading,
}) {
  const Api = useApi() 
  var row_data_juridictions = [];
  var api_keys = [];
  const [show, changeShow] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [modal_togFirst, setmodal_togFirst] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [updatedcredsstatus, setUpdatedCredsStatus] = useState(false);
  const [activeRows, setActiveRows] = useState([]);
  const { userIsAdmin, availableJurisdictions, userIsDeveloper } = useAuth();
  const [showApiKeys, setShowApiKeys] = useState({});
  row_data_juridictions = rowdata.map((row) => row.jurisdiction).flat();
  api_keys = rowdata.map((row) => row.apikey).flat();
  const UpdateButtonStatus = (e) => {
    let checkbox_status = e.target.checked;
    let checkbox_id = e.target.id;
    if (checkbox_status === true) {
      const updatedRows = [...activeRows, parseInt(checkbox_id)];
      row_ids.push(parseInt(checkbox_id));
      changeShow(true);
      setActiveRows(updatedRows);
    } else {
      const updatedRows = activeRows.filter((id) => id !== checkbox_id);
      row_ids.pop(parseInt(checkbox_id));
      setActiveRows(updatedRows);
    }
    if (row_ids.length === 0) {
      setActiveRows([]);
      changeShow(false);
    }
  };

  useEffect(() => {}, [activeRows]);

  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);

  const addServer = () => {
    let company_name = document.getElementById("companyinput").value;
    let apikey = document.getElementById("apikeyinput").value;
    let ownerinput = document.getElementById("ownerinput").value;
    if (!api_keys.includes(apikey)) {
      let new_proxy = {
        company_name: company_name,
        apikey: apikey,
        ownerinput: ownerinput,
      };
      let data = { request_for: "add_proxy", proxy_details: new_proxy };
      Api.proxiesCrudOps(data).then((data) => {
        if (data.rows) {
          let rows = data.rows;
          setRows(rows);
          setmodal_togFirst(false);
        } else {
          toggleErrorModal();
          setmodal_togFirst(false);
        }
      });
    } else {
      setmodal_togFirst(false);
    }
  };

  const handleCellClick = (e) => {
    const newData = [...rowdata];
    const field = e.target.parentElement.dataset.col;
    const id = e.target.parentElement.dataset.row;
    const value = e.target.value;
    newData[id][field] = value;
    setRows(newData);
  };

  const handleBlur = (e) => {
    const field = e.target.parentElement.dataset.col;
    const id = e.target.parentElement.dataset.id;
    const value = e.target.value;
    let data = {
      request_for: "update_proxy",
      proxy_update_details: [field, id, value],
    };
    Api.proxiesCrudOps(data).then((data) => {
      if (data.rows) {
        let rows = data.rows;
        setRows(rows);
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
        if (row_ids.includes(parseInt(id))) {
          document.getElementById(id).checked = true;
        }
        setmodal_togFirst(false);
      } else {
        toggleErrorModal();
        setUpdatedCredsStatus(false);
      }
    });
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    setIsDeleting(false);
    let data = { request_for: "delete_proxy", row_ids: row_ids };
    Api.proxiesCrudOps(data).then((data) => {
      let rows = data.rows;
      setRows(rows);
      toggleModal();
    });
  };

  const handleRemovejurisdiction = (event, row, col, value) => {
    event.preventDefault();
    const updatedRowData = [...rowdata];
    updatedRowData[row][col] =
      updatedRowData[row][col] &&
      updatedRowData[row][col].filter((item) => item !== value);
    // setRows(updatedRowData)
    const row_id = event.target.parentElement.parentElement.parentElement.dataset.id;
    const column = col;
    let cell_value = updatedRowData[row][col];
    let data = {
      request_for: "update_jurisdiction",
      params: [column, row_id, cell_value],
    };
    Api.proxiesCrudOps(data).then((data) => {
      let rows = data.rows;
      setRows(rows);
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      if (row_ids.includes(parseInt(row_id))) {
        document.getElementById(row_id).checked = true;
      }
    });
  };
  function getLatestAvailableMonth(day) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const latestMonth = new Date(currentYear, currentMonth, day);
    if (latestMonth < currentDate) {
      latestMonth.setMonth(latestMonth.getMonth() + 1);
    }
    return `${day} ${latestMonth.toLocaleDateString('en-US', { month: 'long' })}`;
  }
  
  const handleDrop = (event, row, id, col) => {
    event.preventDefault();
    const value = event.dataTransfer.getData("text");
    const updatedRowData = [...rowdata];
    const row_id = id;
    const column = col;
    if (updatedRowData[row][col] && !updatedRowData[row][col].includes(value)) {
      updatedRowData[row][col] && updatedRowData[row][col].push(value);
      updatedRowData[row][col] = updatedRowData[row][col]
        ? updatedRowData[row][col]
        : value;
      let cell_value = updatedRowData[row][col];
      let data = {
        request_for: "update_jurisdiction",
        params: [column, row_id, cell_value],
      };
      Api.proxiesCrudOps(data).then((data) => {
        let rows = data.rows;
        setRows(rows);
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
        if (row_ids.includes(parseInt(row_id))) {
          document.getElementById(row_id).checked = true;
        }
      });
    }
  };
  const toggleApiKeyVisibility = (index) => {
    setShowApiKeys({
      ...showApiKeys,
      [index]: !showApiKeys[index],
    });
  };
  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  return (
    <>
      <div className="d-flex flex-row-reverse justify-content-between align-items-center pt-4 pb-2 gap-4">
        {userIsAdmin ? (
          <>
            <button
              style={{ visibility: show ? "visible" : "hidden" }}
              className="delete-btn btn btn-sm btn-danger btn-label"
              onClick={toggleModal}
            >
              <i
                className={` btn-danger bx bx-trash label-icon align-middle`}
              ></i>
              <div className="delete-btn-txt">Delete</div>
            </button>
            <button
              className="add-btn btn btn-sm btn-success btn-label"
              onClick={() => setmodal_togFirst(!modal_togFirst)}
            >
              <i
                className={` btn-success bx bx-plus label-icon align-middle`}
              ></i>
              <div className="delete-btn-txt">Add Proxies</div>
            </button>
          </>
        ) : (
          ""
        )}
        <Modal
          modalTransition={{ timeout: 0 }}
          isOpen={modal_togFirst}
          toggle={() => {
            setmodal_togFirst(!modal_togFirst);
          }}
          id="firstmodal"
          centered
        >
          <ModalHeader className="p-5" style={{ display: "flex" }}>
            <Col style={{ display: "flex" }}>
              <h5
                style={{ width: "96%", display: "flex" }}
                className="modal-title"
                id="exampleModalToggleLabel"
              >
                ADD NEW PROXY
              </h5>
              <Button
                style={{ paddingRight: 0 }}
                type="button"
                className="btn-close"
                onClick={() => {
                  setmodal_togFirst(false);
                }}
                aria-label="Close"
              ></Button>
            </Col>
          </ModalHeader>
          <ModalBody className="p-5">
            <div className="row g-3">
              <Col lg={12}>
                <div>
                  <label htmlFor="companyinput" className="form-label">
                    Company Name
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="companyinput"
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <label htmlFor="apikeyinput" className="form-label">
                    API Key
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="apikeyinput"
                  />
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <label htmlFor="ownerinput" className="form-label">
                    Owner
                  </label>
                  <Input type="text" className="form-control" id="ownerinput" />
                </div>
              </Col>
            </div>
          </ModalBody>
          <ModalFooter style={{ marginRight: "4%" }}>
            <div className="row g-3">
              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    className={`cart-btn btn btn-md btn-warning btn-label`}
                    onClick={() => {
                      addServer();
                    }}
                  >
                    <i className={`mdi mdi-server label-icon align-middle`}></i>
                    <div className="cart-btn-txt">Add</div>
                  </button>
                </div>
              </Col>
            </div>
          </ModalFooter>
        </Modal>
        <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
          <div className="modal-content border-0">
            <ModalBody className="p-0  status-popup">
              <div
                className={
                  updatedcredsstatus
                    ? "alert alert-success alert-dismissible  show m-0"
                    : "alert alert-danger alert-dismissible  show m-0"
                }
                role="alert"
              >
                {updatedcredsstatus ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: "Details has been Added",
                    }}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: "Something Went Wrong",
                    }}
                  />
                )}
                <button
                  type="button"
                  className="btn-close alrt-clse"
                  onClick={toggleErrorModal}
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
          </div>
        </Modal>
        <Modal isOpen={isOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>CONFIRM DELETE</ModalHeader>
          <ModalBody>Are you sure you want to delete this item?</ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              disabled={isDeleting}
              onClick={toggleModal}
            >
              Cancel
            </Button>{" "}
            <Button color="danger" disabled={isDeleting} onClick={handleDelete}>
              {isDeleting ? "Deleting..." : "Delete"}
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div
        className="card"
        id="drag-source"
        style={{
          display: jurisdictions.includes(row_data_juridictions)
            ? "none"
            : "flex",
        }}
      >
        <div>
          {jurisdictions.sort().map((val, key) => {
            return (
              <span
                draggable={activeRows.length > 0 ? "true" : "false"}
                key={key}
                onDragStart={(e) => {
                  e.dataTransfer.setData("text/plain", e.target.textContent);
                }}
                style={{ fontSize: 12, cursor: "pointer" }}
                className={"badge badge-soft-danger p-2 m-2"}
              >
                {val}
              </span>
            );
          })}
        </div>
      </div>
      <div id="report-table-container">
        <div className="table-responsive" style={{maxHeight:400,overflowY:'auto'}} >
          <table
            id="report-table"
            className="table table-nowrap my-0 py-0 all-users"
          >
            <thead>
              {rowdata.length > 0 ? (
                <tr>
                  <th></th>
                  <th>Company Name</th>
                  <th>Owner</th>
                  <th>API Key</th>
                  <th>Monthly Price</th>
                  <th>Yearly Price</th>
                  <th>Monthly Renewal Date</th>
                  <th>Proxies Count</th>
                  <th>Countries</th>
                  <th>Jurisdictions</th>
                </tr>
              ) : (
                <tr>
                  <th></th>
                </tr>
              )}
            </thead>
            {rowdata.length > 0 ? (
              <tbody>
                {rowdata.map((arr, key) => (
                  <tr style={{ textAlign: "center" }} key={key}>
                    <td>
                      <div
                        className="form-check-success"
                        style={{ textAlign: "center" }}
                      >
                        {userIsAdmin ? (
                          <Input
                            onChange={UpdateButtonStatus}
                            className="form-check-input"
                            type="checkbox"
                            style={{ border: "1px solid #bbbbbb" }}
                            id={arr.id}
                          />
                        ) : (
                          key + 1
                        )}
                      </div>
                    </td>
                    <td data-col="company_name" data-row={key} data-id={arr.id}>
                      {arr.company_name}
                    </td>
                    <td data-col="owner" data-row={key} data-id={arr.id}>
                      {arr.owner}
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {showApiKeys[key] ? (
                          <span>{arr.apikey}</span>
                        ) : (
                          <span style={{ marginTop: 2 }}>{"*".repeat(20)}</span>
                        )}
                        <button
                          onClick={
                            userIsAdmin || userIsDeveloper
                              ? () => toggleApiKeyVisibility(key)
                              : null
                          }
                          disabled={!(userIsAdmin || userIsDeveloper)}
                          className={
                            showApiKeys[key]
                              ? "btn mdi mdi-eye-off"
                              : "btn mdi mdi-eye"
                          }
                        ></button>
                      </div>
                    </td>
                    <td
                      data-col="monthly_price"
                      data-row={key}
                      data-id={arr.id}
                    >
                      {arr.monthly_price}
                    </td>
                    <td data-col="yearly_price" data-row={key} data-id={arr.id}>
                      {arr.yearly_price}
                    </td>
                    <td
                      data-col="next_payment_date"
                      data-row={key}
                      data-id={arr.id}
                    >
                      {activeRows.includes(arr.id) ? (
                         <select className={'form-control'} value={arr.next_payment_date}  onChange={(e) => handleCellClick(e)} onBlur={(e) => {
                          handleBlur(e);
                        }}>
                         <option value="">Select Day</option>
                         {days.map((day) => (
                           <option key={day} value={day}>
                             {day}
                           </option>
                         ))}
                       </select>
                       ) :  (
                        arr.next_payment_date && getLatestAvailableMonth(parseInt(arr.next_payment_date)) )
                      }
                    </td>
                    <td
                      data-col="proxies_count"
                      data-row={key}
                      data-id={arr.id}
                    >
                      {arr.proxies_count}
                    </td>
                    <td data-col="countries" data-row={key} data-id={arr.id}>
                      {Object.keys(arr.countries).length > 0 ? (
                        <div>
                          <table
                            className="table table-bordered mb-0"
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              {Object.entries(arr.countries).map(
                                ([key, value]) => (
                                  <tr key={key}>
                                    <td>{key}</td>
                                    <td>{value}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td
                      data-col="jurisdictions"
                      data-row={key}
                      data-id={arr.id}
                      onDrop={(e) =>
                        handleDrop(e, key, arr.id, "jurisdictions")
                      }
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          minHeight: 50,
                          gap: "1rem",
                        }}
                      >
                        {arr.jurisdictions
                          ? arr.jurisdictions.sort().map((i, e) => {
                              return (
                                <span
                                  key={e}
                                  style={{
                                    fontSize: 12,
                                    cursor: "pointer",
                                    height: 37,
                                    gap:5,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className={
                                    "badge badge-soft-secondary p-2 my-1 "
                                  }
                                >
                                  {i}
                                  {activeRows.includes(arr.id) ? (
                                    <Button
                                      className="jurisdiction-badge  btn-close close-btn-users"
                                      style={{ fontWeight: "bold" ,fontSize:10}}
                                      onClick={(event) =>
                                        handleRemovejurisdiction(
                                          event,
                                          key,
                                          "jurisdictions",
                                          i
                                        )
                                      }
                                    >
                                      <i className="fa fa-close"></i>
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              );
                            })
                          : ""}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                {loading ? (
                  <tr>
                    <td>
                      <div className="loader-container">
                        <div className="loader"></div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td style={{ fontWeight: "400", textAlign: "center" }}>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
