import React from "react";

import MetaTags from "react-meta-tags";
import Proxies from "./proxies";
import { Container } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
const captcha = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>Proxies</title>
          </MetaTags>
          <BreadCrumb title="Proxies" pageTitle="Dashboards" />
          <Proxies />
        </Container>
      </div>
    </>
  );
};

export default captcha;
