import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Row,
  Input,
  ModalHeader,
  Modal,
  ModalBody,
  Label,
  ModalFooter,
} from "reactstrap";
import "./tables.css";
import { useAuth } from "../../context/AuthContext";
import { useApi } from "../../context/ApiContext";
import moment from "moment";
import Select from "react-select";
var func_name;
function Cell({
  status,
  reports_count,
  monitor,
  statusTime,
  developerinput,
  expectedtimeInput,
  date,
  developers,
  monitors,
  site_url,
  weekdays_arr,
  juris_week_days,
  available_clients
}) {
  const [ip, setIpAddr] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [newStatus,setNewStatus] = useState("")
  const [servername, setServerName] = useState(null);
  const [modal_togFirst, setmodal_togFirst] = useState(false);
  const [modal_togSecond, setmodal_togSecond] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [updatedcredsstatus, setUpdatedCredsStatus] = useState(false);
  const [weekdays, setWeekDays] = useState([]);
  const [weekupdate, setWeekupdate] = useState(false);
  const [fieldChanged, setFieldChanged] = useState(false);
  const [jurisdiction, setJurisdiction] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const Api  = useApi()
  const [modalFields, setModalFields] = useState({
    status: "",
    statustime: "",
    developer: [],
    monitor: [],
    expectedTime: "",
    reports_count: "",
    site_url: "",
    clients: [{ email: "", name: "" }]
  });
  const { userIsAdmin, availableJurisdictions } = useAuth();
  const currentTime = moment().format("HH:mm:ss");
  useEffect(() => {
    setModalFields((prev) => ({ ...prev, status: status }));
    setModalFields((prev) => ({ ...prev, reports_count: reports_count }));
    setModalFields((prev) => ({ ...prev, monitor: monitor }));
    setModalFields((prev) => ({ ...prev, expectedTime: expectedtimeInput }));
    setModalFields((prev) => ({ ...prev, statustime: statusTime }));
    setModalFields((prev) => ({ ...prev, developer: developerinput }));
    setModalFields((prev) => ({ ...prev, site_url: site_url }));
    setModalFields((prev) => ({ ...prev, clients: available_clients }));
    setWeekDays(weekdays_arr);

  }, [status,reports_count,monitor,expectedtimeInput,statusTime,developerinput,available_clients]);

  function handleWeekUpdate(e) {
    if (weekupdate === true) {
      let data = { jurisdiction: jurisdiction, weekdays: weekdays };
      Api.UpdateWeekdays(data).then((res) => {
        setWeekDays(JSON.parse(res.weekdays));
      });
      setWeekupdate(!weekupdate);
      window.location.reload();
    } else {
      setWeekupdate(!weekupdate);
    }
  }

  function tog_togFirst() {
    setmodal_togFirst(!modal_togFirst);
  }
 
  function badgeclick(e) {

    show_modal_data(e);
    tog_togFirst();
    let cell = e.target.parentElement.parentElement;
    let selected_date = e.target.parentElement.parentElement.parentElement.firstChild.innerHTML
    func_name = cell
      .closest("tr")
      .parentNode.parentNode.querySelector(
        "th:nth-child(" + (cell.cellIndex + 1) + ") div span"
      ).textContent;
    setJurisdiction(func_name);
    setSelectedDate(selected_date);
  }

  function show_modal_data(e) {
    let cell = e.target.parentElement.parentElement;
    func_name = cell
      .closest("tr")
      .parentNode.parentNode.querySelector(
        "th:nth-child(" + (cell.cellIndex + 1) + ") div span"
      ).textContent;
    const creds = { func_name: func_name };
    Api.UpdateServerDetails(creds).then((res) => {
      setUsername(res.data.username ? res.data.username : null);
      setPassword(res.data.password ? res.data.password : null);
      setIpAddr(res.data.ip_addr ? res.data.ip_addr : null);
      setServerName(res.data.server_name ? res.data.server_name : null);
    });
  }


  function handleStatusChange(value) {
    value !== "" ? setIpAddr(value) : setIpAddr(null);
    if (value) {
      let data = {
        request_for: "get_server_details_by_ip",
        ip_address: value,
      };
      Api.UpdateServerDetails(data).then((res) => {
        setUsername(res.data.username);
        setPassword(res.data.password);
        setServerName(res.data.server_name);
      });
    } else {
      setUsername(null);
      setPassword(null);
      setServerName(null);
      setIpAddr(null);
    }
  }

  const handleDayClick = (day) => {
    if (weekdays.includes(day)) {
      setWeekDays(weekdays.filter((d) => d !== day));
    } else {
      setWeekDays([...weekdays, day]);
    }
  };

  function tog_togSecond() {
    setmodal_togSecond(!modal_togSecond);
  }

  function handleDeveloperChange(e){
    setModalFields((prev) => ({ ...prev, developer: e }));
    setFieldChanged(true);
  }
  function handleMonitorChange(e){
    setModalFields((prev) => ({ ...prev, monitor: e }));
    setFieldChanged(true);
  }
  const toggleErrorModal = () => setErrorModalOpen(!errorModalOpen);
  const todaydate = moment(date, 'MM-DD-YYYY')
  const all_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const daysOfWeek = juris_week_days;
  const dayOfWeek = all_days[todaydate.day()];
  var currentDate = moment().format("MM-DD-YYYY");
  const color = {
    Completed: "success",
    Error: "danger",
    Running: "warning",
    Unknown: "light",
    "Awaiting Start": "info",
    "No Data Found":"dark",
    "No Files Loaded":"pink-badge",
    "Website Not Updated": "purple-badge",
    "Jurisdiction Not Active": "dark"
  };
  useEffect(() => {
    if (status === "Awaiting Start") {
      if (daysOfWeek.length  === 0) {
        setNewStatus("")
      } else {
        if (daysOfWeek.includes(dayOfWeek)) {
          setNewStatus(status)
        } else {
          setNewStatus(`Not on ${dayOfWeek} `)
        }
      }
    } else if (status === "Unknown") {
      if (daysOfWeek.length === 0) {
        setNewStatus("")
      } else {
        if (daysOfWeek.includes(dayOfWeek)) {
          setNewStatus("")
        } else {
          setNewStatus(`Not on ${dayOfWeek} `)
        }
      }
    } else {
      setNewStatus(status)
    }
  },[status,daysOfWeek])
  const handleInputChange = (e, index, field) => {
    const updatedRows  = modalFields.clients
    updatedRows[index][field] = e.target.value;
    setModalFields((prev) => ({ ...prev, clients:updatedRows }));
    if (updatedRows[index].email === "" && updatedRows[index].name === "" && index !== updatedRows.length - 1) {
      updatedRows.splice(index, 1);
      setModalFields((prev) => ({ ...prev, clients:updatedRows }));
    }
    setFieldChanged(true);
  };

  const addRow = () => {
    const currentRow = modalFields.clients[modalFields.clients.length - 1];
    if (currentRow.email === "" || currentRow.name === "") {
      alert("Please fill out the current row before adding a new one.");
      return;
    }  
    const newRow = { email: "", name: "" };
    setModalFields((prev) => ({ ...prev, clients:[...modalFields.clients, newRow] }));
  };
  function handleModalFieldChange(e) {
    if (e.target.id === "ForminputState") {
      setModalFields((prev) => ({ ...prev, status: e.target.value }));
    }
    if (e.target.id === "developerinput") {
      setModalFields((prev) => ({ ...prev, developer: e }));
    }
    if (e.target.id === "monitorinput") {
      setModalFields((prev) => ({ ...prev, monitor: e.target.value }));
    }
    if (e.target.id === "expectedtimeinput") {
      setModalFields((prev) => ({ ...prev, expectedTime: e.target.value }));
    }
    if (e.target.id === "reportsinput") {
      if (/^\d*\.?\d+$/.test(e.target.value) && (parseFloat(e.target.value) >= 0 || e.target.value === "")) {
      setModalFields((prev) => ({ ...prev, reports_count: e.target.value }));
      }
      else{
        setModalFields((prev) => ({ ...prev, reports_count: "" }));
      }
    }
    if (e.target.id === "siteurlinput") {
      setModalFields((prev) => ({ ...prev, site_url: e.target.value }));
    }
    setFieldChanged(true);
  }
  const selectStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
  };
  function handleSubmit(e) {
    const currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
    const nonEmptyClientRows = modalFields.clients.filter((row) => row.email !== '' || row.name !== '');
    let data = {
      jurisdiction: func_name,
      date:selectedDate,
      status: modalFields.status,
      statustime: currentDateTime,
      developer: modalFields.developer.length >0  ? modalFields.developer.map((row) =>{ return row.value }):[],
      monitor:  modalFields.monitor.length >0 ? modalFields.monitor.map((row) =>{ return row.value }) : [],
      expectedTime: modalFields.expectedTime,
      reports_count: modalFields.reports_count,
      clients : nonEmptyClientRows
    };
    Api.UpdateTrackingDetails(data).then((res) => {
      if (res.status === "success") {
        setmodal_togFirst(!modal_togFirst);
      } else {
        toggleErrorModal();
      }
    });
  }
  return (
    <div>
      <span
        onClick={(e) => badgeclick(e)}
        style={{
          fontSize: 12,
          cursor: "pointer",
          color:
          newStatus === "Unknown" || newStatus.includes("Not on")
              ? "#9c9696"
              : newStatus.includes("Error")
              ? "red"
              : "",
          backgroundColor:newStatus.includes("Error")  ? "white" :  newStatus.includes('Awaiting Start') && moment(modalFields.expectedTime,"HH:mm:ss").isBefore(moment(currentTime, "HH:mm:ss"))? 'white' :''
        }}
        className={"badge badge-soft-" + color[newStatus] + " p-2"}
      >
        {newStatus}
        <br />
      </span>
      <Modal
        modalTransition={{ timeout: 0 }}
        isOpen={modal_togFirst}
        toggle={() => {
          tog_togFirst();
        }}
        id="firstmodal"
        centered
      >
        <ModalHeader className="p-5" style={{ display: "flex" }}>
          <Col style={{ display: "flex" }}>
            <h5
              style={{ width: "96%", display: "flex" }}
              className="modal-title"
              id="exampleModalToggleLabel"
            >
              JURISDICTION DETAILS
            </h5>
            <Button
              style={{ paddingRight: 0 }}
              type="button"
              className="btn-close"
              onClick={() => {
                setmodal_togFirst(false);
              }}
              aria-label="Close"
            ></Button>
          </Col>
        </ModalHeader>
        <ModalBody className="p-5">
          <div className="row g-3">
            <Col lg={12}>
              <label htmlFor="statusinput" className="form-label">
                Status
              </label>
              <select
                placeholder="Select status"
                id="ForminputState"
                className="form-select"
                data-choices
                value={modalFields.status ? modalFields.status : ""}
                onChange={handleModalFieldChange}
                readOnly={date === currentDate || userIsAdmin ? false : true}
                data-choices-sorting="true"
              >
                <option value="Awaiting Start">Awaiting Start</option>
                <option value="Completed">Completed</option>
                <option value="Error">Error</option>
                <option value="Jurisdiction Not Active">Jurisdiction Not Active</option>
                <option value="No Data Found">No Data Found</option>
                <option value="No Files Loaded">No Files Loaded</option>
                <option value="Running">Running</option>
                <option value="Website Not Updated">Website Not Updated</option>
              </select>
            </Col>
            <Col lg={12}>
              <label htmlFor="statustimeinput" className="form-label">
                Status Time
              </label>
              <Input
                type="text"
                className="form-control"
                id="Statustimeinput"
                placeholder=""
                value={modalFields.statustime ? modalFields.statustime : ""}
                readOnly={true}
                onChange={handleModalFieldChange}
              />
            </Col>
            <Col lg={12}>
              <label htmlFor="developerinput" className="form-label">
                Developer
              </label>
              <div>
              <Select
                styles={selectStyles}
                id="developerselect"
                value={modalFields.developer}
                isMulti={true}
                isDisabled ={userIsAdmin && date === currentDate ? false : true}
                isClearable={true}
                onChange={handleDeveloperChange}
                options={developers}
              />
            </div>
            </Col>
            <Col lg={12}>
              <label htmlFor="developerinput" className="form-label">
                Monitor
              </label>
              <Select
                styles={selectStyles}
                id="monitorselect"
                value={modalFields.monitor}
                isMulti={true}
                isClearable={true}
                isDisabled ={userIsAdmin && date === currentDate ? false : true}
                onChange={handleMonitorChange}
                options={monitors}
              />
            </Col>
            <Col lg={12}>
                <label htmlFor="siteurlinput" className="form-label">
                  Client Details
                </label>
                <div className="d-flex align-items-center justify-content-end">
                  <button onClick={addRow} className="btn btn-primary"
                    disabled={userIsAdmin && date === currentDate ? false : true}

                  >
                    Add Row
                  </button>
                </div>
                <div
                  className="table-responsive mt-2"
                  style={{ maxHeight: 400, overflowY: "auto" }}
                >
                  <table
                    id="report-table"
                    className="table  table-nowrap my-0 py-0 all-users"
                  >
                    <thead>
                      <tr>
                        <th style={{ height: "1rem" }}>Email</th>
                        <th style={{ height: "1rem" }}>Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modalFields.clients && modalFields.clients.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="email"
                              value={row.email}
                              onChange={(e) =>
                                handleInputChange(e, index, "email")
                              }
                              className="form-control"
                              readOnly={userIsAdmin && date === currentDate ? false : true}

                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={row.name}
                              onChange={(e) =>
                                handleInputChange(e, index, "name")
                              }
                              className="form-control"
                              readOnly={userIsAdmin && date === currentDate ? false : true}

                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Col>
            <Col lg={12}>
              <label htmlFor="developerinput" className="form-label">
                Expected Time For Completion
              </label>
              <Input
                type="time"
                className="form-control"
                id="expectedtimeinput"
                placeholder=""
                value={modalFields.expectedTime ? modalFields.expectedTime : ""}
                readOnly={userIsAdmin && date === currentDate ? false : true}
                onChange={handleModalFieldChange}
              />
            </Col>
            <Col lg={12}>
              <label htmlFor="Demo3" className="form-label">
                Number of Reports Found
              </label>
              <Input
                type="number"
                className="form-control"
                id="reportsinput"
                readOnly={date === currentDate || userIsAdmin ? false : true}
                value={
                  modalFields.reports_count ? modalFields.reports_count : ""
                }
                onChange={handleModalFieldChange}
                onWheel={(e) => e.target.blur()} // <-- Update this line
                onTouchStart={(e) => e.preventDefault()} // Add this line for touch devices
                onTouchMove={(e) => e.preventDefault()} // Add this line for touch devices
                onTouchEnd={(e) => e.preventDefault()}
              />
            </Col>
            <Col lg={12}>
              <label htmlFor="Demo3" className="form-label">
                Site URL
              </label>
              <Input
                type="text"
                className="form-control"
                id="siteurlinput"
                value={modalFields.site_url ? modalFields.site_url : ""}
                readOnly={userIsAdmin && date === currentDate ? false : true}
                onChange={handleModalFieldChange}
              />
            </Col>
            {date === currentDate ? (
              <Col lg={12}>
                <label htmlFor="weekdays" className="form-label">
                  Weekdays
                </label>
                <div style={{ userSelect: "none" }}>
                  <div
                    className={`weekday-pill ${
                      weekdays.includes("Mon") && "selected"
                    }`}
                    onClick={() => (weekupdate ? handleDayClick("Mon") : "")}
                  >
                    Mon
                  </div>
                  <div
                    className={`weekday-pill ${
                      weekdays.includes("Tue") && "selected"
                    }`}
                    onClick={() => (weekupdate ? handleDayClick("Tue") : "")}
                  >
                    Tue
                  </div>
                  <div
                    className={`weekday-pill ${
                      weekdays.includes("Wed") && "selected"
                    }`}
                    onClick={() => (weekupdate ? handleDayClick("Wed") : "")}
                  >
                    Wed
                  </div>
                  <div
                    className={`weekday-pill ${
                      weekdays.includes("Thu") && "selected"
                    }`}
                    onClick={() => (weekupdate ? handleDayClick("Thu") : "")}
                  >
                    Thu
                  </div>
                  <div
                    className={`weekday-pill ${
                      weekdays.includes("Fri") && "selected"
                    }`}
                    onClick={() => (weekupdate ? handleDayClick("Fri") : "")}
                  >
                    Fri
                  </div>
                  <div
                    className={`weekday-pill ${
                      weekdays.includes("Sat") && "selected"
                    }`}
                    onClick={() => (weekupdate ? handleDayClick("Sat") : "")}
                  >
                    Sat
                  </div>
                  <div
                    className={`weekday-pill ${
                      weekdays.includes("Sun") && "selected"
                    }`}
                    onClick={() => (weekupdate ? handleDayClick("Sun") : "")}
                  >
                    Sun
                  </div>
                </div>
              </Col>
            ) : (
              ""
            )}
          </div>
        </ModalBody>
        <ModalFooter style={{ marginRight: "4%" }}>
          <div className="row g-3">
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-end">
                  <>
                    {userIsAdmin && date == currentDate ? (
                      <button
                        onClick={(e) => {
                          handleWeekUpdate(e);
                        }}
                        className={`update-btn btn btn-md btn-primary btn-label`}
                      >
                        {weekupdate ? (
                          <>
                            <i
                              className={`mdi  mdi-update label-icon align-middle`}
                            ></i>
                            <div className="cart-btn-txt" style={{fontSize:12}}>Update Weekdays</div>
                          </>
                        ) : (
                          <>
                            <i
                              className={`mdi  mdi-lead-pencil label-icon align-middle`}
                            ></i>
                            <div className="cart-btn-txt" style={{fontSize:12}}>Edit Weekdays</div>
                          </>
                        )}
                      </button>
                    ) : (
                      ""
                    )}
                    { userIsAdmin || currentDate === date ? ( <button
                      onClick={handleSubmit}
                      disabled={fieldChanged  ? false : true}
                      className={`update-btn btn btn-md btn-primary btn-label`}
                    >
                      <i
                        className={` mdi mdi-update label-icon align-middle`}
                      ></i>
                      <div className="cart-btn-txt"  style={{fontSize:12}}>Update</div>
                    </button>):'' } 
                   
                    <button
                      className={`cart-btn btn btn-md btn-warning btn-label`}
                      onClick={() => {
                        tog_togSecond();
                        tog_togFirst(false);
                      }}
                    >
                      <i
                        className={`mdi mdi-server label-icon align-middle`}
                      ></i>
                      <div className="cart-btn-txt"  style={{fontSize:12}}>Server Details</div>
                    </button>
                  </>
              </div>
            </Col>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        modalTransition={{ timeout: 0 }}
        isOpen={modal_togSecond}
        toggle={() => {
          tog_togSecond();
        }}
        id="secondmodal"
        centered
      >
        <ModalHeader className="p-5" style={{ display: "flex" }}>
          <Col style={{ display: "flex" }}>
            <h5
              style={{
                display: "flex",
                width: "96%",
              }}
              className="modal-title"
              id="exampleModalToggleLabel"
            >
              SERVER DETAILS
            </h5>
            <Button
              style={{ paddingRight: 0 }}
              type="button"
              className="btn-close"
              onClick={() => {
                setmodal_togSecond(false);
              }}
              aria-label="Close"
            ></Button>
          </Col>
        </ModalHeader>
        <ModalBody className="p-5">
          <div className="row g-3">
            <Col lg={12}>
              <div>
                <Label htmlFor="ipaddr" className="form-label">
                  IP Address
                </Label>
                <Row md={12}>
                  <Col>
                    <Input
                      type="text"
                      className="form-control"
                      value={ip ? ip : ""}
                      disabled={true}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={12}>
              <Label htmlFor="ForminputState" className="form-label">
                Server Name
              </Label>
              <Row md={12}>
                <Col>
                  <Input
                    type="text"
                    className="form-control"
                    value={servername ? servername : ""}
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={12}>
              <label htmlFor="username" className="form-label">
                UserName
              </label>
              <Input
                type="text"
                className="form-control"
                value={username ? username : ""}
                disabled={true}
              />
            </Col>
            <Col lg={12}>
              <div>
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <Input
                  type="text"
                  className="form-control"
                  value={password ? password : ""}
                  disabled={true}
                />
              </div>
            </Col>
          </div>
        </ModalBody>
        <ModalFooter style={{ marginRight: "4%" }}>
          <div className="row g-3">
            <Col lg={12}>
              <div className="hstack gap-2 justify-content-end">
                <button
                  onClick={() => {
                    tog_togSecond(false);
                    tog_togFirst();
                  }}
                  className={`back-btn btn btn-md btn-primary btn-label`}
                >
                  <i
                    className={` bx bx-arrow-back label-icon align-middle`}
                  ></i>
                  <div className="cart-btn-txt">Back</div>
                </button>
                {/* <button
                  onClick={() => {
                    handleUpdate();
                  }}
                  className={`update-btn btn btn-md btn-warning btn-label`}
                >
                  <i className={`mdi mdi-update label-icon align-middle`}></i>
                  <div className="upadte-btn-txt">Update</div>
                </button> */}
              </div>
            </Col>
          </div>
        </ModalFooter>
      </Modal>
      <Modal isOpen={errorModalOpen} toggle={toggleErrorModal}>
        <div className="modal-content border-0">
          <ModalBody className="p-0  status-popup">
            <div
              className={
                updatedcredsstatus
                  ? "alert alert-success alert-dismissible  show m-0"
                  : "alert alert-danger alert-dismissible  show m-0"
              }
              role="alert"
            >
              {updatedcredsstatus ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: "Details has been Updated",
                  }}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: "Something Went Wrong" }}
                />
              )}
              <button
                type="button"
                className="btn-close alrt-clse"
                onClick={toggleErrorModal}
                aria-label="Close"
              ></button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}

export default Cell;
